import React, { useMemo } from 'react';
import { Card, CardContent, Typography, Box, Link } from '@mui/material';
import times from 'lodash/times';
import { useGetTopDealsToday } from '@hooks/useTopDeals';
import DealCard, { SkeletonDealCard } from '@components/DealCard';

const TopDealsCard = ({ tag }: { tag: string }) => {
  const { data: topDealsData, isLoading } = useGetTopDealsToday();

  const mode = topDealsData?.mode || 'today';
  const deals = useMemo(
    () => topDealsData?.deals.splice(0, 3) || [],
    [topDealsData]
  );
  const title =
    mode === 'today' ? `Today's Top 3 Deals` : `This Week's 3 Top Deals`;

  return (
    <Card
      style={{
        margin: '0px 0rem 1rem 1rem'
      }}
    >
      <CardContent>
        <Box id="subscribe-save-tips" />
        <Typography variant="h6" component="h2" gutterBottom>
          {isLoading ? 'Loading most popular...' : title}
        </Typography>
        {deals.map((deal) => (
          <DealCard
            key={deal.ASIN}
            {...deal}
            shouldLazyLoad={false}
            tag={tag}
          />
        ))}
        {isLoading ? times(3, (i) => <SkeletonDealCard key={i} />) : null}
        {!isLoading ? (
          <Link
            variant="h5"
            href="https://www.jungle.deals/p/best-selling-deals"
            sx={{
              fontWeight: 'bold'
            }}
          >
            See all top deals &gt;&gt;&gt;
          </Link>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default TopDealsCard;
