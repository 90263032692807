/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation, useQuery, UseQueryResult } from 'react-query';
import axios from '@utils/axios';
import { FavoriteDealType } from '@types';
import queryClient from '../queryClient';
import { useGetUserData } from './useGetUserData';

export function useGetFavorites(): UseQueryResult<string[]> {
  const { data: user } = useGetUserData();
  const queryFn = async (): Promise<string[]> => {
    const result = await axios.get<{
      data: string[];
    }>(`/api/user/favorites`);
    return result.data;
  };

  return useQuery<string[], Error>(`favorite-deals`, () => queryFn(), {
    enabled: !!user,
    onError: () => {
      console.error('Something went wrong while fetching favorites');
    }
  });
}

export function useGetAllFavorites(): UseQueryResult<FavoriteDealType[]> {
  const { data: user } = useGetUserData();
  const queryFn = async (): Promise<FavoriteDealType[]> => {
    const result = await axios.get<{
      data: FavoriteDealType[];
    }>(`/api/user/all-favorites`);
    return result.data;
  };

  return useQuery<FavoriteDealType[], Error>(
    `all-favorite-deals`,
    () => queryFn(),
    {
      enabled: !!user,
      onError: () => {
        console.error('Something went wrong while fetching all favorites');
      }
    }
  );
}

export function useToggleFavorite() {
  const mutationFn = async ({
    ASIN,
    finalPrice
  }: {
    ASIN: string;
    finalPrice?: number;
  }): Promise<void> => {
    await axios.post<{
      data: string;
    }>(`/api/user/toggle-favorite`, {
      ASIN,
      price: finalPrice
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('favorite-deals');
      queryClient.refetchQueries('all-favorite-deals');
    },
    onError: () => {
      console.error('Error sending');
    }
  });
}
