const Cookies = {
  getCookie: (name: string): string | null => {
    if (typeof document === 'undefined') {
      return null;
    }
    const v = document.cookie?.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? decodeURIComponent(v[2]) : null;
  },

  setCookie: (name: string, value: any, days: number): void => {
    if (typeof document === 'undefined') {
      return;
    }
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value};path=/;expires=${d.toUTCString()}`;
  },

  deleteCookie: (name: string) => {
    Cookies.setCookie(name, '', -1);
  }
};

export default Cookies;
