import React, { useState } from 'react';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Avatar,
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useGetUserData } from '@hooks/useGetUserData';

const AdminChip = ({
  onlyShowAveragePriceLower,
  setOnlyShowAveragePriceLower,
  onlySponsored,
  setOnlySponsored,
  totalSoldLessThan,
  setTotalSoldLessThan,
  totalSoldMoreThan,
  setTotalSoldMoreThan,
  onlyShowOlderThanDays,
  setOnlyShowOlderThanDays
}: {
  onlyShowAveragePriceLower: boolean;
  setOnlyShowAveragePriceLower: (value: boolean) => void;
  onlySponsored: boolean;
  setOnlySponsored: (value: boolean) => void;
  totalSoldLessThan: number | null;
  setTotalSoldLessThan: (value: number | null) => void;
  totalSoldMoreThan: number | null;
  setTotalSoldMoreThan: (value: number | null) => void;
  onlyShowOlderThanDays: number | null;
  setOnlyShowOlderThanDays: (value: number | null) => void;
}) => {
  const { data: userData } = useGetUserData();
  const [modalOpen, setModalOpen] = useState(false);

  const isAdmin = React.useMemo(() => {
    return !!userData?.isAdmin;
  }, [userData]);

  const resetAll = () => {
    setOnlyShowAveragePriceLower(false);
    setOnlySponsored(false);
    setTotalSoldLessThan(null);
    setTotalSoldMoreThan(null);
    setOnlyShowOlderThanDays(null);
  };

  const chipStyles = {
    marginLeft: '8px',
    marginBottom: '8px'
  };

  const renderModal = () => (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 535 } }}
      maxWidth="xs"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <DialogTitle>Admin Filters</DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            margin: '8px 0'
          }}
        >
          <Chip
            label="Only Show Average Price Lower"
            onClick={() => {
              setOnlyShowAveragePriceLower(!onlyShowAveragePriceLower);
            }}
            sx={chipStyles}
            variant={onlyShowAveragePriceLower ? 'filled' : 'outlined'}
            avatar={onlyShowAveragePriceLower ? <Avatar>✅</Avatar> : undefined}
            onDelete={
              onlyShowAveragePriceLower
                ? () => {
                    setOnlyShowAveragePriceLower(false);
                  }
                : undefined
            }
          />
          <Chip
            label="Only Sponsored"
            sx={chipStyles}
            onClick={() => {
              setOnlySponsored(!onlySponsored);
            }}
            variant={onlySponsored ? 'filled' : 'outlined'}
            avatar={onlySponsored ? <Avatar>✅</Avatar> : undefined}
            onDelete={
              onlySponsored
                ? () => {
                    setOnlySponsored(false);
                  }
                : undefined
            }
          />
          <TextField
            label="Total Sold Less Than"
            type="text" // Use 'text' to avoid spin buttons
            value={totalSoldLessThan ?? ''}
            onChange={(e) => {
              const value = e.target.value
                ? parseInt(e.target.value, 10)
                : null;
              setTotalSoldLessThan(value);
            }}
            sx={{
              marginTop: '12px',
              width: '100%'
            }}
            InputProps={{
              endAdornment:
                totalSoldLessThan !== null ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear input"
                      onClick={() => setTotalSoldLessThan(null)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
            }}
          />

          <TextField
            label="Total Sold More Than"
            type="text" // Use 'text' to avoid spin buttons
            value={totalSoldMoreThan ?? ''}
            onChange={(e) => {
              const value = e.target.value
                ? parseInt(e.target.value, 10)
                : null;
              setTotalSoldMoreThan(value);
            }}
            sx={{
              marginTop: '12px',
              width: '100%'
            }}
            InputProps={{
              endAdornment:
                totalSoldMoreThan !== null ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear input"
                      onClick={() => setTotalSoldMoreThan(null)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
            }}
          />
          <TextField
            label="Only show older than days"
            type="text" // Use 'text' to avoid spin buttons
            value={onlyShowOlderThanDays ?? ''}
            onChange={(e) => {
              const value = e.target.value
                ? parseInt(e.target.value, 10)
                : null;
              setOnlyShowOlderThanDays(value);
            }}
            sx={{
              marginTop: '12px',
              width: '100%'
            }}
            InputProps={{
              endAdornment:
                onlyShowOlderThanDays !== null ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear input"
                      onClick={() => setOnlyShowOlderThanDays(null)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px'
        }}
      >
        <Button
          onClick={() => {
            resetAll();
            setModalOpen(false);
          }}
        >
          Clear
        </Button>
        <Button
          autoFocus
          onClick={() => {
            setModalOpen(false);
          }}
          color="primary"
          variant="outlined"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  const hasFilter = React.useMemo(() => {
    return (
      onlyShowAveragePriceLower ||
      totalSoldLessThan !== null ||
      onlySponsored ||
      totalSoldMoreThan !== null ||
      onlyShowOlderThanDays !== null
    );
  }, [
    onlyShowAveragePriceLower,
    onlySponsored,
    totalSoldLessThan,
    totalSoldMoreThan,
    onlyShowOlderThanDays
  ]);

  const filterAmount = React.useMemo(() => {
    let amount = 0;
    if (onlyShowAveragePriceLower) amount++;
    if (onlySponsored) amount++;
    if (totalSoldLessThan !== null) amount++;
    if (totalSoldMoreThan !== null) amount++;
    if (onlyShowOlderThanDays !== null) amount++;
    return amount;
  }, [
    onlyShowAveragePriceLower,
    onlySponsored,
    totalSoldLessThan,
    totalSoldMoreThan,
    onlyShowOlderThanDays
  ]);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      {renderModal()}
      <Chip
        label="Admin Filters"
        onClick={() => {
          setModalOpen(true);
        }}
        sx={{
          marginRight: '6px'
        }}
        variant={hasFilter ? 'filled' : 'outlined'}
        avatar={filterAmount > 0 ? <Avatar>{filterAmount}</Avatar> : undefined}
        onDelete={
          hasFilter
            ? () => {
                resetAll();
              }
            : undefined
        }
      />
    </>
  );
};

export default AdminChip;
