import React from 'react';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';

import { useGetUserData } from '@hooks/useGetUserData';
import { useUpdateDealsByBrand } from '@hooks/useDeals';
import { useSnackbar } from '@hooks/useSnackbar';

const RecheckBrandDeals = ({
  brand,
  onCloseCallback
}: {
  brand: string;
  onCloseCallback: () => void;
}) => {
  const { data: user } = useGetUserData();
  const { mutate: updateDealsByBrand } = useUpdateDealsByBrand();
  const { showMessage } = useSnackbar();

  if (!user?.isAdmin || !brand) {
    return null;
  }

  const handleRecheckBrandClick = () => {
    updateDealsByBrand(
      { brand },
      {
        onSuccess: (data) => {
          onCloseCallback();
          showMessage(
            `Rechecking ${data?.rechecking || 0} ${brand} brand ${
              data?.rechecking > 1 ? 'deals' : 'deal'
            }...`
          );
        }
      }
    );
  };

  return (
    <>
      <MenuItem key="admin-recheck-brand" onClick={handleRecheckBrandClick}>
        <ListItemIcon>
          <AssignmentTurnedInIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Recheck {brand} Brand Deals</ListItemText>
      </MenuItem>
    </>
  );
};

export default RecheckBrandDeals;
