import { useHistory } from 'react-router-dom';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';

export interface PageType {
  name: string;
  link?: string;
  onClick?: () => void;
  children?: PageType[];
}

export const usePageOnClick = () => {
  const history = useHistory();

  const pageOnClick = (page: PageType): void => {
    if (page.link) {
      trackUse({
        item: 'menu-link-click',
        value: `${page.link}`,
        type: 'CLICK'
      });

      logPostHogEvent('menu-link-click', {
        link: `${page.link}`,
        type: 'CLICK'
      });

      history.push(page.link);

      if (typeof window !== 'undefined') {
        // scroll to top
        window?.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    } else if (page.onClick) {
      trackUse({
        item: 'menu-click',
        value: `${page.name}`,
        type: 'CLICK'
      });

      logPostHogEvent('menu-click', {
        value: `${page.link}`,
        type: 'CLICK'
      });

      page.onClick();
    }
  };

  return pageOnClick;
};

export const lightIconStyles = {
  color: '#fff'
};

export const logoStyle = {
  mr: 2,
  display: { xs: 'block' },
  flexGrow: 1,
  color: 'inherit',
  textDecoration: 'none',
  fontSize: {
    xs: '1.2rem',
    sm: '1.5rem'
  }
};

export const emailIconContainerStyle = {
  // if less than 350px width do not show
  '@media (max-width: 350px)': {
    display: 'none !important'
  }
};

export const mainStyles = {
  flexGrow: 1,
  p: 0,
  width: {
    xs: '100%'
  },
  maxWidth: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '82rem',
    xl: '82rem'
  },
  margin: '0 auto'
};
