/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Link, Typography } from '@mui/material';

interface DatabaseEndMessageProps {
  isFiltered: boolean;
  resetAll: () => void;
}

const DatabaseEndMessage = ({
  isFiltered,
  resetAll
}: DatabaseEndMessageProps) => {
  return (
    <Box
      width="100%"
      sx={{
        padding: '16px'
      }}
    >
      <Box
        textAlign="center"
        sx={{
          margin: { xs: '30px auto', md: '45px auto' },
          padding: '16px',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 2,
          maxWidth: '500px',
          backgroundColor: 'background.paper',
          boxShadow: 1,
          transform: 'translateZ(0)', // Force GPU rendering
          willChange: 'transform' // Hint to browser
        }}
      >
        {isFiltered ? (
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            There are no more results
          </Typography>
        ) : (
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            Yay! You&apos;ve seen it all 🎉
          </Typography>
        )}
        {isFiltered && (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            <Link
              component="button"
              onClick={resetAll}
              sx={{
                textDecoration: 'none',
                fontWeight: 500,
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              Remove your filters
            </Link>{' '}
            to explore more deals!
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DatabaseEndMessage;
