import React from 'react';
import { Typography, Box } from '@mui/material';

export const ButtonWrapper = ({
  button,
  label
}: {
  button: React.ReactElement;
  label: React.ReactNode;
}) => {
  return (
    <Box textAlign="center" display="block">
      <Box>{button}</Box>
      <Typography
        sx={{
          display: 'block',
          textAlign: 'center'
        }}
        variant="caption"
        component="div"
      >
        {label}
      </Typography>
    </Box>
  );
};
