import { Box, CardContent, CardActions, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { DealPostType } from '@types';
import colors from '@utils/colors';

export interface DealPostCardProps extends DealPostType {
  shouldLazyLoad: boolean;
  shouldNotLinkSubscribeSave?: boolean;
  tag?: string;
  hideSummary?: boolean;
  hideAdminActions?: boolean;
  maxWidth?: number;
  hideFavoriteButton?: boolean;
  previewMode?: boolean;
  isLoading?: boolean;
  extraLine?: string | null;
  modalMessage?: string | null;
  extraComponent?: React.ReactNode | null;
  loginMessage?: string | null;
  isFirst?: boolean;
  shouldShowChips?: boolean;
}

const enforcedFontStyles = {
  fontSize: '14px',
  lineHeight: '22px'
};

export const styles = {
  link: {
    fontWeight: 700,
    textDecoration: 'none',
    color: colors.linkColor,
    transition: 'color .5s',
    ...enforcedFontStyles,
    ':hover': {
      color: '#c9cf6a'
    }
  }
};

export const DealCardActions = styled(CardActions)(() => ({
  justifyContent: 'flex-end',
  borderRadius: '3px',
  maxHeight: 'fit-content'
}));

export const DealCardSpacer = styled(Box)(() => ({
  display: 'none'
}));

export const DealCardContent = styled(CardContent)(() => ({}));

export const DealCardImageSkeleton = styled(Skeleton)(({ width, height }) => ({
  maxWidth: `${width}px!important`,
  maxHeight: `${height}px!important`,
  minWidth: `${width}px!important`,
  minHeight: `${height}px!important`,
  display: 'block',
  margin: '0px auto 16px auto',
  textAlign: 'center'
}));

export const fakeDeal: DealPostType = {
  title: 'Loading...',
  ASIN: 'Loading...',
  couponFixed: 0,
  couponPercentage: 0,
  ss: 0,
  maxSs: 0,
  finalPrice: 0,
  currentPrice: 0,
  listPrice: 0,
  qty: 0,
  qtyTerm: 'Loading...',
  dateFirstActive: new Date().toISOString(),
  parentASIN: 'Loading...',
  promoFixed: 0,
  image600Url: 'Loading...',
  image240Width: 240,
  image240Height: 240,
  image600Width: 600,
  image600Height: 600,
  extraDeal: 'Loading...',
  promoCode: null,
  postDate: 'Loading...',
  extraDealLink: null,
  postTitle: 'Loading...',
  qtyLimit: 30,
  extraDealPrice: null,
  image: 'Loading...',
  category: 'Loading...',
  extraDealPriceEach: null,
  priceAfterCoupon: 20
};
