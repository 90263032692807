import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box, Button } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import trackUse from '@utils/trackUse';

interface LoadingPhrasesProps {
  phrases: string[];
  delay?: number; // Optional: Delay in milliseconds for switching phrases
}

const LoadingPhrases: React.FC<LoadingPhrasesProps> = ({
  phrases,
  delay = 2000
}) => {
  const [currentIndex, setCurrentIndex] = useState(
    phrases.length > 0 ? Math.floor(Math.random() * phrases.length) : 0
  );
  const [showRefetchButton, setShowRefetchButton] = useState(false);
  const buttonTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const mountTimeRef = useRef<number>(Date.now()); // Store the mount time

  const resetButtonTimeout = () => {
    // Clear existing timeout if it exists
    if (buttonTimeoutRef.current) {
      clearTimeout(buttonTimeoutRef.current);
    }
    // Start a new timeout
    buttonTimeoutRef.current = setTimeout(() => {
      setShowRefetchButton(true);
    }, 4000);
  };

  useEffect(() => {
    if (!phrases || phrases.length === 0) {
      return undefined; // Explicitly return undefined to satisfy ESLint
    }

    let isMounted = true; // Track whether the component is mounted
    const interval = setInterval(() => {
      if (isMounted) {
        setCurrentIndex(() => Math.floor(Math.random() * phrases.length));
      }
    }, delay);

    // Initialize the refetch button timeout
    resetButtonTimeout();

    // Cleanup interval and timeout on unmount
    return () => {
      isMounted = false;
      clearInterval(interval);
      if (buttonTimeoutRef.current) {
        clearTimeout(buttonTimeoutRef.current);
      }
    };
  }, [phrases, delay]);

  const handleReload = async () => {
    try {
      const elapsedTime = Date.now() - mountTimeRef.current; // Calculate elapsed time since mount
      trackUse({
        value: `Reload after ${elapsedTime / 1000}s`, // Convert ms to seconds
        item: 'reload-button',
        type: 'ACTION'
      });

      window?.location?.reload();
      setShowRefetchButton(false);
      resetButtonTimeout(); // Reset the timeout after refetching
    } catch (error) {
      console.error('Failed to refetch deals:', error);
    }
  };

  // Handle case when phrases are not loaded or empty
  if (!phrases || phrases.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        padding: '10px',
        borderRadius: '8px',
        textAlign: 'center'
      }}
    >
      {showRefetchButton ? (
        <Box>
          <Typography
            variant="body2"
            component="p"
            sx={{ marginBottom: '8px' }}
          >
            You may have a poor connection. Try reloading the deals.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CachedIcon />}
            onClick={handleReload}
          >
            Reload deals
          </Button>
        </Box>
      ) : (
        <Typography variant="body2" component="p" sx={{ marginBottom: '8px' }}>
          {phrases[currentIndex]}
        </Typography>
      )}
    </Box>
  );
};

export default LoadingPhrases;
