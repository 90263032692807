import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { useGetUserData } from '@hooks/useGetUserData';
import { DealPostType } from '@types';
import strategyIds from '@utils/constants/strategyIds';
import ShowIcon from '@components/ShowIcon';
import { fixCategory } from '@utils/categories';
import categories from '../../../../../../config/categoryFilter';

const getCategoryIconFromCategory = (category: string) => {
  const categoryIcon = categories.find(
    (cat) => cat?.matches?.includes(category)
  );
  return categoryIcon?.icon;
};

const DealCardActions = (props: DealPostType) => {
  const { strategyId, category } = props;
  const { data: user } = useGetUserData();
  const catIcon = getCategoryIconFromCategory(fixCategory(category));

  const isAdmin = !!user?.isAdmin;

  const getStrategyIds = () => {
    if (!strategyId) {
      return [];
    }
    return strategyId.split(',').map((id) => id.trim()) || [];
  };

  // eslint-disable-next-line no-constant-condition
  if (!isAdmin || true) {
    return null;
  }

  const getStrategyIconNames = () => {
    const names = getStrategyIds().map((id) => {
      const strategy = strategyIds.find(
        (strategyId) => strategyId.value === id
      );
      return {
        icon: strategy?.icon,
        label: strategy?.label
      };
    });

    return names.filter((iconName) => !!iconName.icon);
  };

  const strategyIcons = getStrategyIconNames();

  return (
    <Box>
      {strategyIcons && strategyIcons.length > 0 && (
        <>
          {strategyIcons.map((iconName) => {
            return (
              <Tooltip
                key={iconName.label}
                title={iconName.label}
                placement="top"
              >
                <span>
                  <ShowIcon
                    icon={iconName.icon}
                    style={{
                      fontSize: '24px'
                    }}
                  />
                </span>
              </Tooltip>
            );
          })}
        </>
      )}
      {catIcon && (
        <Tooltip key="category" title={`Cat: ${category}`} placement="top">
          <span>
            <ShowIcon
              icon={catIcon}
              style={{
                fontSize: '24px'
              }}
            />
          </span>
        </Tooltip>
      )}
    </Box>
  );
};

export default DealCardActions;
