import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import config from '@configFile';
import NewTooltip from '@components/NewTooltip';
import {
  formatPrice,
  getProductLink,
  isDateWithinLast24Hours,
  parseTitle,
  getPostPrice
} from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';
import { DealPostCardProps } from '../../utils';

const DealCardTitle = (dealProps: DealPostCardProps): JSX.Element => {
  const {
    title,
    ASIN,
    currentPrice,
    listPrice,
    dateFirstActive,
    tag = config.AFFILIATE_TAGS.DEFAULT
  } = dealProps;

  const link = getProductLink(ASIN, tag);
  const isNew = !!(
    dateFirstActive && isDateWithinLast24Hours(new Date(dateFirstActive))
  );

  const theme = useTheme();

  return (
    <>
      <Typography variant="body2" component="div">
        {isNew && <NewTooltip deal={dealProps} />}
        <MonetizingLink
          href={link}
          tag={tag}
          clickType="deal-card-title"
          sx={{
            '@media (max-width: 540px)': {
              fontSize: '14px!important'
            },
            fontWeight: 500,
            color: theme.palette.linkColor,
            textDecoration: 'none',
            transition: 'color .5s',
            letterSpacing: '0.00938em',
            fontSize: '14px',
            lineHeight: '22px',
            ':hover': {
              color: '#c9cf6a'
            }
          }}
          aria-label={`View deal details${getPostPrice(
            dealProps
          )} at Amazon.com`}
        >
          {parseTitle(title)}
        </MonetizingLink>
        {` — ${formatPrice(currentPrice)}`}
        {listPrice && currentPrice !== listPrice
          ? ` (reg. ${formatPrice(listPrice)})`
          : null}
      </Typography>
    </>
  );
};

export default DealCardTitle;
