/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import axios from '@utils/axios';
import { UserSettings } from '@api/database/models/userSettings';
import { useSnackbar } from '@hooks/useSnackbar';
import queryClient from '../queryClient';
import { useGetUserData } from './useGetUserData';

type UIUserSettings = Pick<
  UserSettings,
  'databasePercentageBasis' | 'databaseShowRibbons' | 'databaseStickyFilters'
>;

export const defaultUISettings = {
  databasePercentageBasis: 'LIST_PRICE',
  databaseShowRibbons: true,
  databaseStickyFilters: false
};

export function useUpdateUserSettings() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    [key: string]: any;
  }

  const mutationFn = async (updateBody: RequestData): Promise<void> => {
    return axios.post(`/api/user-settings/update`, {
      updateBody
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('user-settings');
      showMessage('Setting updated');
    },
    onError: () => {
      console.error('error updating settings');
    }
  });
}

export function useGetUserSettings(): UseQueryResult<UIUserSettings> {
  const { data: user } = useGetUserData();

  const queryFn = async (): Promise<UIUserSettings> => {
    const result = await axios.get<{
      data: UIUserSettings;
    }>(`/api/user-settings/get`);
    return result.data;
  };

  return useQuery<UIUserSettings, Error>(`user-settings`, () => queryFn(), {
    enabled: !!user,
    // temporary data while fetching
    placeholderData: {
      databasePercentageBasis: 'LIST_PRICE',
      databaseShowRibbons: true,
      databaseStickyFilters: false
    }
  });
}
