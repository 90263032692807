import { removeNonAlphanumeric } from './index';

const cleanASIN = (ASIN: string): string => {
  return removeNonAlphanumeric(ASIN).trim();
};

export const getASINFromURL = (urlPossibleNoSlash: string): string => {
  // Ensure the URL ends with a slash for uniform processing
  const url = `${urlPossibleNoSlash}/`;

  // Define patterns to match different URL formats containing an ASIN
  const patterns = [
    /dp\/([^/?%]+)/,
    /product\/([^/?%]+)/,
    /ASIN\/([^/?%]+)/,
    /offer-listing\/([^/?%]+)/,
    /product-reviews\/([^/?%]+)/
  ];

  // Use array.find to execute the regex tests and return the first match found
  const match = patterns.find((pattern) => url.match(pattern));
  if (match) {
    const asinMatch = (url || '').match(match);
    if (asinMatch && asinMatch[1]) {
      return cleanASIN(asinMatch[1]);
    }
  }

  // If no ASIN is found in the URL, return an empty string
  return '';
};
