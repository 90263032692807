/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import forEach from 'lodash/forEach';
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import axios from '@utils/axios';
import config from '@configFile';
import Cookies from '@utils/Cookies';
import useLocalStorage, {
  clearLocalStorageValue
} from '@hooks/useLocalStorage';
import queryClient from '../queryClient';
import { UserType } from '../types';
import { useSnackbar } from './useSnackbar';

export function useLogoutUser() {
  const { showMessage } = useSnackbar();

  const mutationFn = async (): Promise<null> => {
    return axios.post('/api/user-logout', { withCredentials: true });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      showMessage('Logging out...');
      window?.location?.replace(config.CURRENT_URL);
      const valuesToClear = [
        'dashboard_filter_category_filters',
        'dashboard_filter_with_reviews',
        'dashboard_filter_without_posts',
        'dashboard_filter_only_subscribe_save',
        'dashboard_filter_only_coupons',
        'dashboard_filter_only_favorites',
        'dashboard_sort',
        'categoryFilter',
        'sortOrder',
        'form_firstName',
        'form_lastName',
        'form_email'
      ];

      if (window) {
        forEach(valuesToClear, (value) => {
          clearLocalStorageValue(value);
        });
      }
      queryClient.clear();
    },
    onError: () => {
      console.error('error updating banned term');
    }
  });
}

const setPostHogIdentity = (data: UserType | null) => {
  const uniqueId = Cookies?.getCookie(config.POST_HOG_COOKIE_NAME);

  // @ts-ignore
  if (typeof posthog === 'undefined' || !uniqueId) {
    return;
  }

  // @ts-ignore
  const currentId = posthog?.get_distinct_id();

  try {
    if (
      data?.firstName &&
      data?.lastName &&
      data?.email &&
      uniqueId &&
      !currentId
    ) {
      // @ts-ignore
      posthog?.identify(uniqueId, {
        email: data?.email,
        name: `${data.firstName.trim()} ${data.lastName.trim()}`,
        isAdmin: data?.isAdmin
      });

      // @ts-ignore
      posthog?.people?.set({
        $email: data?.email,
        name: `${data.firstName.trim()} ${data.lastName.trim()}`
      });

      if (data?.isAdmin) {
        // @ts-ignore
        posthog?.people?.set({ $email: data?.email, isAdmin: true });
        // add to admin group
        // @ts-ignore
        posthog?.group?.('admin', `${data?.email}`, { $email: data?.email });
        // @ts-ignore
        posthog?.stopSessionRecording();
      }
    } else if (!currentId) {
      // @ts-ignore
      posthog?.identify(uniqueId);
    }
  } catch (err) {
    // console.log('error setting posthog ident', err);
  }
};

export function useGetUserData(): UseQueryResult<UserType> {
  const queryFn = async (): Promise<UserType> => {
    const result = await axios.post<{ data: UserType }>(
      `/api/get-user-data`,
      {}
    );
    return result.data;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isLoggedIn, setIsLoggedIn] = useLocalStorage('registeredUser', true);

  return useQuery<UserType, Error>(`user-data`, () => queryFn(), {
    onSuccess: (data) => {
      try {
        if (data?.firstName && data?.lastName && data?.email) {
          setPostHogIdentity(data);
          setIsLoggedIn(true);
        }
      } catch (err) {
        console.log('err setting login', err);
      }
    },
    onError: () => {
      setIsLoggedIn(false);
      setPostHogIdentity(null);
      // Error here just means they are not logged in.
      // console.error('Something went wrong while seeing if the user was logged in');
    },
    refetchInterval: 2 * 60 * 1000 // 2 minutes in milliseconds
  });
}
