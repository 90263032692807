import React from 'react';
import { Box, ButtonGroup, Button } from '@mui/material';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import { useTheme } from '@mui/system';
import trackUse from '@utils/trackUse';
import { parseTitle } from '@utils/index';
import { DealPostType } from '@types';

const SubscribeAndSaveChooser = ({
  dealData,
  onChange,
  selected
}: {
  dealData: DealPostType;
  onChange: (value: number) => void;
  selected: number;
}) => {
  const theme = useTheme();
  const buttonValues: number[] = [];

  for (let i = 0; i <= dealData.maxSs; i += 5) {
    if (
      !(dealData.maxSs === 15 && i === 10) &&
      !(dealData.maxSs === 20 && i === 15) &&
      !(dealData.maxSs === 20 && i === 10)
    ) {
      buttonValues.push(i);
    }
  }

  if (buttonValues.length < 2) {
    return null;
  }

  const clickAction = (value: number) => {
    trackUse({
      item: 's-s-chooser-deal-receipt',
      value: `${dealData?.ASIN} ${parseTitle(dealData?.title)} — ${value}%`,
      type: 'ACTION'
    });

    onChange(value);
  };

  return (
    <Box display="flex">
      <ButtonGroup
        variant="outlined"
        size="small"
        aria-label="Subscribe and Save chooser"
      >
        {buttonValues.map((value) => (
          <Button
            key={value}
            // if value === selected then put it in a selected state
            // if value !== selected then put it in a non-selected state
            variant={value === selected ? 'contained' : 'outlined'}
            onClick={() => {
              clickAction(value);
            }}
            aria-label={
              value === 0
                ? 'No Subscribe & Save Discount'
                : `${value}% Subscribe & Save Discount`
            }
            sx={{
              backgroundColor:
                value === selected
                  ? `${theme.palette.selectedButtonBackgroundColor} !important`
                  : 'initial'
            }}
          >
            {value === 0 ? <SyncDisabledIcon /> : `${value}%`}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default SubscribeAndSaveChooser;
