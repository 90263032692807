/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult } from 'react-query';
import axios from '@utils/axios';
import { DealPostType } from '../types';

const queryTopDeals = async (): Promise<DealPostType[]> => {
  const result = await axios.get<{ data: DealPostType[] }>(
    `/api/get-top-deals`
  );

  return result.data;
};

export function useGetTopDeals(): UseQueryResult<DealPostType[]> {
  return useQuery<DealPostType[], Error>(`top-deals`, () => queryTopDeals(), {
    onError: () => {
      console.error('Something went wrong while fetching the top deals');
    }
  });
}

const queryTopDealsToday = async (): Promise<{
  mode: string;
  deals: DealPostType[];
}> => {
  const result = await axios.get<{
    data: {
      mode: string;
      deals: DealPostType[];
    };
  }>(`/api/get-top-deals-today`);

  return result.data;
};

export function useGetTopDealsToday(): UseQueryResult<{
  mode: string;
  deals: DealPostType[];
}> {
  return useQuery<
    {
      mode: string;
      deals: DealPostType[];
    },
    Error
  >(`top-deals-today`, () => queryTopDealsToday(), {
    onError: () => {
      console.error('Something went wrong while fetching the top deals');
    }
  });
}
