import React from 'react';
import times from 'lodash/times';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/system';
import { getPostPrice, parseTitle } from '@utils/index';
import config from '@configFile';
import ReportInaccurate from '@components/ReportInaccurate';
import ShareButton from '@components/ShareButton';
import FavoriteButton from '@components/FavoriteButton';
import AdminActions from '@components/AdminActions';
import { DealPostCardProps, DealCardActions } from '../../utils';
import classes from './styles';

const DealCardActionsButtons = (dealProps: DealPostCardProps): JSX.Element => {
  const {
    ASIN,
    finalPrice,
    hideFavoriteButton = false,
    hideSummary = false,
    hideAdminActions = false,
    isLoading = false,
    slug,
    title
  } = dealProps;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { previewMode = false, ...propsToPass } = dealProps;
  const theme = useTheme();
  return (
    <DealCardActions
      data-testid="deal-card-actions"
      sx={{
        ...classes.dealCardActions
      }}
    >
      <Box
        sx={{
          ...classes.actionsBox,
          backgroundColor: theme.palette.actionsBackgroundColor
        }}
      >
        {isLoading ? (
          <Box sx={classes.actionsBoxInner}>
            {times(3, (i) => (
              <Skeleton
                key={i}
                variant="circular"
                width={34}
                height={34}
                sx={{
                  marginBottom: '4px',
                  marginLeft: '4px',
                  marginRight: '4px'
                }}
              />
            ))}
          </Box>
        ) : (
          <>
            {!hideSummary ? <ReportInaccurate ASIN={ASIN} /> : null}
            <ShareButton
              title={
                !hideSummary
                  ? `${parseTitle(title)}${getPostPrice(dealProps)}`
                  : undefined
              }
              url={!hideSummary ? `${config.SITE_URL}/blog/${slug}` : undefined}
            />
            {hideFavoriteButton ? null : (
              <FavoriteButton ASIN={ASIN} finalPrice={finalPrice} />
            )}
            {hideAdminActions ? null : (
              <AdminActions ASIN={ASIN} deal={propsToPass} />
            )}
          </>
        )}
      </Box>
    </DealCardActions>
  );
};

export default DealCardActionsButtons;
