/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import IconButton from '@mui/material/IconButton';

import { useTheme } from '@mui/system';
import config from '@configFile';
import { useGetUserData } from '@hooks/useGetUserData';
import trackUse from '@utils/trackUse';
import { DealPostType } from '@types';
import { getProductLink, formatPrice, logPostHogEvent } from '@utils/index';
import CouponLists from '../../pages/Coupons/components/CouponLists';
import DealHasCoupon from '../DealHasCoupon';
import MonetizingLink from '../MonetizingLink';

interface CouponChipProps extends DealPostType {
  onlyCoupons?: boolean;
  toggleOnlyCoupons?: () => void;
  tag?: string;
  showCouponText?: boolean;
}

const CouponChip = (props: CouponChipProps) => {
  const {
    ASIN,
    toggleOnlyCoupons,
    onlyCoupons,
    tag,
    promoCode,
    couponPercentage,
    couponFixed,
    showCouponText = true
  } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const affiliateTag = tag || config.AFFILIATE_TAGS.DEAL;
  const { data: user } = useGetUserData();
  const [couponChipOpen, setCouponChipOpen] = useState(false);

  const usedPromoCodes = user?.allUsedCoupons || [];
  const isCouponUsed =
    usedPromoCodes.length > 0
      ? usedPromoCodes.some((usedCoupon) => usedCoupon.couponId === promoCode)
      : null;

  const handleCouponChipClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    setCouponChipOpen(true);
    logPostHogEvent('open-coupon-chip', {
      item: 'open-coupon-chip',
      value: ASIN,
      type: 'CLICK'
    });
    trackUse({
      item: 'open-coupon-chip',
      value: ASIN,
      type: 'CLICK'
    });
  };

  const handleCouponChipClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    console.log('e', e);
    setCouponChipOpen(false);
  };

  const couponOff = couponPercentage
    ? `${couponPercentage}%`
    : `${formatPrice(couponFixed)}`;

  const couponTooltip = isCouponUsed
    ? 'You have already used this coupon.'
    : `This deal has a ${couponOff} coupon available.`;

  return (
    <span key="c-chip">
      <Tooltip enterDelay={1000} placement="top" title={couponTooltip}>
        <Chip
          id={`coupon_chip_${ASIN}`}
          sx={{
            marginRight: '4px !important',
            fontSize: '11px !important',
            backgroundColor: isCouponUsed
              ? '#00000021 !important'
              : `${
                  isDarkMode ? 'transparent' : theme.palette.couponColor
                } !important`,
            color: isCouponUsed ? 'black !important' : 'white !important',
            borderColor: theme.palette.couponColor,
            marginTop: '0.35em !important'
          }}
          size="small"
          onClick={handleCouponChipClick}
          icon={
            isCouponUsed ? (
              <RemoveCircleIcon
                sx={{
                  color: 'black !important',
                  fontSize: '13px !important',
                  marginLeft: '6px !important'
                }}
              />
            ) : undefined
          }
          label={
            isCouponUsed
              ? 'Coupon used'
              : `${couponOff} Off${showCouponText ? ' Coupon' : ''}`
          }
          variant={isDarkMode ? 'outlined' : 'filled'}
        />
      </Tooltip>
      <Dialog
        open={couponChipOpen}
        onClose={handleCouponChipClose}
        maxWidth="md"
        fullWidth
        sx={{
          padding: '0 0 10px 0',
          margin: '16px',
          '@media (max-width: 540px)': {
            margin: '8px'
          }
        }}
        PaperProps={{
          sx: {
            margin: '8px',
            width: '100%'
          }
        }}
      >
        <DialogTitle>Coupon details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCouponChipClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            padding: '0'
          }}
          onClick={(e: React.MouseEvent) => {
            e?.stopPropagation();
          }}
        >
          <Box>
            {!promoCode ? (
              <Typography sx={{ p: 2 }}>
                {`There's a ${couponOff} coupon available for a discount on this product below the product price.`}{' '}
                <MonetizingLink
                  target="_blank"
                  variant="body1"
                  tag={affiliateTag}
                  clickType="coupon-link-redeem-to-account"
                  href={getProductLink(ASIN, affiliateTag)}
                  underline="always"
                >
                  <strong>
                    Click on the coupon on Amazon to redeem it to your account
                    before checkout.
                  </strong>
                </MonetizingLink>
              </Typography>
            ) : null}
            {promoCode ? (
              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  marginLeft: 2,
                  marginRight: 2,
                  marginBottom: 2,
                  marginTop: 0,
                  '@media (max-width: 540px)': {
                    padding: '4px'
                  }
                }}
              >
                <DealHasCoupon promoCode={promoCode} tag={tag} />
              </Box>
            ) : null}
          </Box>

          {promoCode ? (
            <Box>
              <Typography
                variant="body1"
                sx={{
                  margin: '4px 16px 4px 16px'
                }}
              >
                Click the coupon link to see all Amazon items this one time
                coupon will work on.
              </Typography>
              <Box
                sx={{
                  padding: '16px',
                  marginTop: '10px',
                  backgroundColor: '#80808029',
                  '@media (max-width: 540px)': {
                    padding: '6px'
                  }
                }}
              >
                <CouponLists singleCode={promoCode} openExpanded tag={tag} />
              </Box>
            </Box>
          ) : null}
          {toggleOnlyCoupons ? (
            <Box>
              <FormControl sx={{ p: 2, margin: '10px' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={onlyCoupons}
                      onChange={toggleOnlyCoupons}
                    />
                  }
                  label="Only show deals with coupons"
                />
              </FormControl>
            </Box>
          ) : null}
          <DialogActions>
            <Button onClick={handleCouponChipClose}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </span>
  );
};

export default CouponChip;
