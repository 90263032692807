import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  useTheme,
  Card
} from '@mui/material';
import { useEmailSignUp, useGetUserMailingLists } from '@hooks/useEmail';
import { isValidEmail } from '@utils/formatUtils';
import { useSnackbar } from '@hooks/useSnackbar';
import config from '@configFile';
import { sanitizeEmail } from '@utils/securityUtils';
import { getMailingListDisplayNameFromId } from '@utils/mailingLists';
import { useGetUserData } from '@hooks/useGetUserData';
import SpinningIcon from '@components/SpinningIcon';

const SignupForm = ({
  customTitle = `Don't Miss a Deal!`,
  customPrompt = `This deal is no longer available. Sign up to our Hot Deals email
list to make sure you don't miss the next one!`,
  mailingList = config.sendGrid.mailingLists.hotDeals,
  showCard = false
}: {
  customTitle?: string;
  customPrompt?: string;
  mailingList?: string;
  showCard?: boolean;
}) => {
  const theme = useTheme();
  const { data: user, isLoading: isLoadingUser } = useGetUserData();
  const displayName = getMailingListDisplayNameFromId(mailingList);
  const { data: mailingListData, isLoading: isLoadingMailingListData } =
    useGetUserMailingLists();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [hasSignedUp, setHasSignedUp] = useState(false);
  const isDarkMode = theme.palette.mode === 'dark';
  const { mutate: signUp, isLoading } = useEmailSignUp();
  const { showMessage } = useSnackbar();
  const mailingLists = mailingListData || [];

  const userIsAlreadySubscribed = useMemo(() => {
    return mailingLists.includes(mailingList);
  }, [mailingLists, mailingList]);

  useEffect(() => {
    if (user?.email && user?.hasVerifiedEmail) {
      setEmail(user.email);
    }
  }, [user]);

  const signUpForDeals = () => {
    if (!isValidEmail(email)) {
      setEmailError(true);
      return;
    }

    setEmailError(false);

    signUp(
      {
        email,
        selectedEmails: [mailingList],
        location: 'signup-form-database-page'
      },
      {
        onSuccess: () => {
          setEmail('');
          setHasSignedUp(true);
          showMessage(
            `Successfully signed up for ${displayName} mailing list!`
          );
        },
        onError: () => {
          showMessage(
            `There was an error signing up for ${displayName} mailing list. Please try again later.`
          );
        }
      }
    );
  };

  const thankYou = `Thank you for signing up! You'll receive our ${displayName} emails soon!`;
  const buttonText = `Sign Up for ${displayName}`;

  const loadingStyle = {
    backgroundColor: isDarkMode ? '#b397ee' : '#3b5998',
    color: '#fff',
    '&:hover': {
      backgroundColor: isDarkMode ? '#9f84d6' : '#365899'
    },
    mb: 1
  };

  if (userIsAlreadySubscribed || isLoadingUser || isLoadingMailingListData) {
    return null;
  }

  const renderForm = () => {
    return (
      <>
        {hasSignedUp ? (
          // Show success message after sign-up
          <Typography
            variant="h6"
            align="center"
            sx={{ color: isDarkMode ? '#fff' : '#000' }}
          >
            {thankYou}
          </Typography>
        ) : (
          <>
            <Typography
              variant="h6"
              component="div"
              align="center"
              gutterBottom
              sx={{ color: isDarkMode ? '#fff' : '#000' }}
            >
              {customTitle}
            </Typography>

            <Box marginBottom="16px">
              <Typography
                variant="body1"
                align="center"
                gutterBottom
                sx={{ color: isDarkMode ? '#bbb' : '#333' }}
              >
                {customPrompt}
              </Typography>
            </Box>
            {user?.email && user?.hasVerifiedEmail ? null : (
              <TextField
                label="Enter your email"
                variant="outlined"
                fullWidth
                size="small"
                sx={{
                  mb: 2,
                  backgroundColor: isDarkMode ? '#424242' : '#fff',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: isDarkMode ? '#777' : '#ccc'
                    },
                    '&:hover fieldset': {
                      borderColor: isDarkMode ? '#b397ee' : '#3b5998'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: isDarkMode ? '#b397ee' : '#3b5998'
                    }
                  }
                }}
                value={email}
                onChange={(e) => {
                  setEmail(sanitizeEmail(e.target.value));
                }}
                error={emailError}
                helperText={
                  emailError ? 'Please enter a valid email address' : ''
                }
              />
            )}
            <Button
              variant="contained"
              fullWidth
              sx={loadingStyle}
              onClick={() => signUpForDeals()}
              startIcon={isLoading ? <SpinningIcon isLoading /> : null}
              disabled={isLoading}
            >
              {isLoading ? 'Signing up...' : buttonText}
            </Button>
            <Typography
              variant="caption"
              align="center"
              sx={{
                color: isDarkMode ? '#bbb' : '#333',
                fontSize: '0.875rem',
                mt: 1
              }}
            >
              Will be used in accordance with our{' '}
              <Link
                href="https://www.jungle.deals/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: isDarkMode ? '#b397ee' : '#3b5998' }}
              >
                Privacy Policy
              </Link>
            </Typography>
          </>
        )}
      </>
    );
  };

  if (showCard) {
    return (
      <Card
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '16px',
          maxWidth: '500px',
          width: '100%',
          margin: '0 auto',
          marginTop: '48px',
          mb: 4,
          boxSizing: 'border-box',
          transform: 'translateZ(0)', // Force GPU rendering
          willChange: 'transform' // Hint to browser
        }}
      >
        {renderForm()}
      </Card>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isDarkMode ? '#2e2e2e' : '#f5f5f5',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: isDarkMode
          ? '0px 4px 12px rgba(0, 0, 0, 0.2)'
          : '0px 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        width: '100%',
        margin: '0 auto',
        marginTop: '48px',
        mb: 4,
        boxSizing: 'border-box',
        transform: 'translateZ(0)', // Force GPU rendering
        willChange: 'transform' // Hint to browser
      }}
    >
      {renderForm()}
    </Box>
  );
};

export default SignupForm;
