/* Create new list / update new list */
/* get list of lists */
/* delete list */
/* get individual list */
import axios from '@utils/axios';
import config from '@configFile';
import {
  ThunkAction,
  ThunkDispatch,
  List,
  LISTS_FETCHED_LISTS,
  LISTS_LOADED_LIST,
  LIST_VIEW_LOADED,
  LIST_VIEW_SET_CURRENT_SLUG,
  ListPreviews
} from '../types';

export const saveList =
  (listData: List, listId: number): ThunkAction =>
  async (dispatch: ThunkDispatch) => {
    try {
      await axios.post('/api/make-lists/list', {
        list: listData,
        listId
      });

      const { data: newData } = await axios.get<{
        data: ListPreviews;
      }>('/api/make-lists/lists');
      dispatch({ type: LISTS_FETCHED_LISTS, listPreviews: newData });
    } catch (err) {
      // console.error('Something went wrong saving the list', listData, listId);
      // console.log('err', err);
    }
  };

export const getLists = (): ThunkAction => async (dispatch: ThunkDispatch) => {
  try {
    const { data } = await axios.get<{
      data: ListPreviews;
    }>('/api/make-lists/lists');
    dispatch({ type: LISTS_FETCHED_LISTS, listPreviews: data });
  } catch (err) {
    // TODO: Add real logger
    // console.error('Something went wrong getting the list previews');
    // console.log('err', err);
  }
};

export const deleteList =
  (deleteListId: number): ThunkAction =>
  async (dispatch: ThunkDispatch) => {
    try {
      const { data } = await axios.post<{
        data: ListPreviews;
      }>('/api/make-lists/removeList', {
        deleteListId
      });
      dispatch({ type: LISTS_FETCHED_LISTS, listPreviews: data });
    } catch (err) {
      // TODO: Add real logger
      // console.error('Something went wrong getting the list previews');
      // console.log('err', err);
    }
  };

export const getList =
  (listId: number): ThunkAction =>
  async (dispatch: ThunkDispatch) => {
    try {
      const { data } = await axios.get<{
        data: List;
      }>(`/api/make-lists/list?id=${listId}`);
      dispatch({ type: LISTS_LOADED_LIST, list: data });
    } catch (err) {
      // TODO: Add real logger
      // console.error('Something went wrong getting the list previews');
      // console.log('err', err);
    }
  };

export const loadListFromSlug =
  (listSlug: string): ThunkAction =>
  async (dispatch: ThunkDispatch) => {
    try {
      const baseURL =
        process.env.baseURL || `http://${config.host}:${config.port}`;
      const endpoint = `${baseURL}/api/make-lists/list?slug=${listSlug}`;
      dispatch({ type: LIST_VIEW_SET_CURRENT_SLUG, currentSlug: listSlug });
      const requestStartedAt = new Date().getTime();
      const { data } = await axios.get<{
        data: List;
      }>(endpoint);
      console.log(
        `${endpoint} - ${new Date().getTime() - requestStartedAt} ms`
      );
      dispatch({ type: LIST_VIEW_LOADED, list: data });
    } catch (err) {
      console.error(err);
      // TODO: Add real logger
      // console.error('Something went wrong getting the list previews');
      // console.log('err', err);
    }
  };
