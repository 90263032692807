import React, { memo, useMemo, useCallback } from 'react';
import times from 'lodash/times';
import Box from '@mui/material/Box';
import { Skeleton, Typography, Card } from '@mui/material';
import config from '@configFile';
import { useGetPromotions } from '@hooks/usePromotions';
import { formatPrice, getCurrentDealEventStrObj } from '@utils/index';
import { getExtraDeal } from '@utils/extraDealMapper';
import MonetizingLink from '@components/MonetizingLink';
import CouponDiscount from '@components/CouponDiscount';
import CouponChip from '@components/CouponChip';
import SubscribeSaveChip from '@components/SubscribeSaveChip';
import ExtraDealLine from '@utils/extraDealMapper/components/ExtraDealLine';
import { CarouselRibbon } from '@components/Ribbon';
import SubscribeSaveLink from './components/SubscribeSaveLink';
import PreviewDiv from './components/PreviewDiv';
import DealCardActionsButtons from './components/DealCardActionsButtons';
import DealCardTitle from './components/DealCardTitle';
import DealCardImage from './components/DealCardImage';
import {
  styles,
  DealPostCardProps,
  fakeDeal,
  DealCardImageSkeleton,
  DealCardContent,
  DealCardSpacer
} from './utils';

export const SkeletonDealCard = (): JSX.Element => {
  return <DealCard {...fakeDeal} shouldLazyLoad isLoading />;
};

const DealCard = memo((dealProps: DealPostCardProps): JSX.Element => {
  const {
    couponFixed,
    couponPercentage,
    ss,
    maxSs,
    finalPrice,
    qty,
    qtyTerm,
    promoFixed,
    shouldNotLinkSubscribeSave,
    tag = config.AFFILIATE_TAGS.DEFAULT,
    image240Width,
    image240Height,
    extraDeal,
    promoCode,
    isLoading = false,
    extraLine = null,
    extraComponent = null,
    modalMessage = null,
    loginMessage = null,
    shouldShowChips = false
  } = dealProps;

  const { previewMode = false, ...propsToPass } = dealProps;

  const { data: promotions } = useGetPromotions();

  const currentPromotionLinks = useMemo(() => {
    return (promotions || []).map((promotion) => promotion.extraDealLink);
  }, [promotions]);

  const isExtraDealLinkApproved = useMemo(() => {
    return currentPromotionLinks.includes(dealProps.extraDealLink);
  }, [currentPromotionLinks, dealProps.extraDealLink]);

  const eachPhase = useMemo(() => {
    return qty && qtyTerm
      ? ` or as low as JUST ${formatPrice(finalPrice / qty)}/${qtyTerm}`
      : '';
  }, [qty, qtyTerm, finalPrice]);

  const getSubscribeSaveLink = useCallback(() => {
    return shouldNotLinkSubscribeSave ? (
      'Subscribe & Save'
    ) : (
      <SubscribeSaveLink />
    );
  }, [shouldNotLinkSubscribeSave]);

  const finalPriceStr = useMemo(() => (ss ? 'as low as' : 'just'), [ss]);
  const extraDealObj = useMemo(() => getExtraDeal(dealProps), [dealProps]);

  const renderPreviewDiv = useMemo(() => {
    if (!previewMode) {
      return null;
    }
    return (
      <PreviewDiv loginMessage={loginMessage} modalMessage={modalMessage} />
    );
  }, [previewMode, loginMessage, modalMessage]);

  const renderCouponLine = useMemo(() => {
    if (!couponFixed && !couponPercentage) {
      return null;
    }
    return (
      <CouponDiscount
        couponFixed={couponFixed}
        couponPercentage={couponPercentage}
        promoCode={promoCode}
        tag={tag}
      />
    );
  }, [couponFixed, couponPercentage, promoCode, tag]);

  const renderSubscribeSaveLink = useMemo(() => {
    if (!ss || !maxSs) {
      return null;
    }
    return (
      <Typography sx={{ mb: 0 }} variant="body2">
        {getSubscribeSaveLink()} to get up to an extra{' '}
        <strong>{maxSs}% off</strong>
      </Typography>
    );
  }, [ss, maxSs, getSubscribeSaveLink]);

  const renderPromoFixed = useMemo(() => {
    if (!promoFixed) {
      return null;
    }
    return (
      <Typography variant="body2">
        {`Automatically get an extra `}
        <strong>{`${formatPrice(promoFixed)} off`}</strong>
        {` at checkout`}
      </Typography>
    );
  }, [promoFixed]);

  const renderFinalPrice = useMemo(() => {
    return (
      <Typography variant="body2">
        <strong>{`Final price ${finalPriceStr} ${formatPrice(
          finalPrice
        )}${eachPhase} shipped!`}</strong>
      </Typography>
    );
  }, [finalPriceStr, finalPrice, eachPhase]);

  const renderCouponChips = useMemo(() => {
    if (!shouldShowChips || (!maxSs && !couponFixed && !couponPercentage)) {
      return null;
    }
    return (
      <Box>
        {couponFixed || couponPercentage ? (
          <CouponChip {...propsToPass} />
        ) : null}
        {maxSs ? <SubscribeSaveChip {...propsToPass} /> : null}
      </Box>
    );
  }, [shouldShowChips, maxSs, couponFixed, couponPercentage, propsToPass]);

  const renderExtraLine = useMemo(() => {
    if (!extraLine) {
      return null;
    }
    return (
      <Box sx={{ margin: { xs: '4px 0 4px 0', md: '4px 0 6px 0' } }}>
        <Typography display="inline" variant="body2" color="text.secondary">
          {extraLine}
        </Typography>
      </Box>
    );
  }, [extraLine]);

  const renderExtraComponent = useMemo(() => {
    if (!extraComponent) {
      return null;
    }
    return extraComponent;
  }, [extraComponent]);

  const renderExtraDealObjPricing = useMemo(() => {
    if (!extraDealObj) {
      return null;
    }
    return <ExtraDealLine deal={dealProps} tag={tag} />;
  }, [extraDealObj, dealProps, tag]);

  const renderApprovedPromotion = useMemo(() => {
    if (
      !extraDealObj &&
      extraDeal &&
      dealProps.extraDealLink &&
      isExtraDealLinkApproved
    ) {
      return (
        <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
          *Also eligible for promotion{' '}
          <MonetizingLink
            href={`${dealProps.extraDealLink}?tag=${tag}`}
            style={styles.link}
            tag={tag}
            clickType="promotion-link"
            aria-label="View deal promotion at Amazon.com"
          >
            {dealProps.extraDeal.toLowerCase()}
          </MonetizingLink>
          !*
        </Typography>
      );
    }
    return null;
  }, [
    extraDealObj,
    extraDeal,
    dealProps.extraDealLink,
    isExtraDealLinkApproved,
    tag
  ]);

  const renderLoadingSkeleton = useMemo(() => {
    return times(5, (i) => (
      <Skeleton
        key={i}
        variant="text"
        width="100%"
        height={24}
        sx={{ marginBottom: '4px' }}
      />
    ));
  }, []);

  const renderMainContent = useMemo(() => {
    return (
      <>
        <Box maxWidth="600px" margin="0 auto">
          <DealCardTitle {...dealProps} />
          {renderCouponLine}
          {renderSubscribeSaveLink}
          {renderPromoFixed}
          {renderFinalPrice}
          {renderExtraDealObjPricing}
          {renderApprovedPromotion}
          {renderExtraLine}
          {renderCouponChips}
          {renderExtraComponent}
        </Box>
      </>
    );
  }, [
    dealProps,
    renderCouponLine,
    renderSubscribeSaveLink,
    renderPromoFixed,
    renderFinalPrice,
    renderExtraDealObjPricing,
    renderApprovedPromotion,
    renderExtraLine,
    renderCouponChips,
    renderExtraComponent
  ]);

  const renderCardContent = useMemo(() => {
    return (
      <Box data-testid="deal-card-content-box" margin="0 auto">
        <Box
          textAlign="left"
          lineHeight="20px"
          data-testid="deal-card-content-box"
          margin="0 auto"
        >
          {isLoading ? renderLoadingSkeleton : renderMainContent}
        </Box>
        <DealCardSpacer className="deal-card-spacer" />
      </Box>
    );
  }, [isLoading, renderLoadingSkeleton, renderMainContent]);

  const renderDealCardContentComponent = useMemo(() => {
    const event = getCurrentDealEventStrObj();
    return (
      <DealCardContent
        data-testid="deal-card-content"
        className="deal-card-content"
        sx={{
          padding: { xs: '8px 0px', sm: '8px 16px' }
        }}
      >
        {renderCardContent}
        {!isLoading ? (
          <CarouselRibbon
            onlyShowImportant
            dealSummary={dealProps}
            top="6px"
            opacity={event?.showRibbonsAtFullOpacity ? 1 : 0.9}
          />
        ) : null}
        <DealCardActionsButtons {...dealProps} />
      </DealCardContent>
    );
  }, [renderCardContent, isLoading, dealProps]);

  const renderDealCardContent = useMemo(() => {
    return (
      <>
        <Box
          component="div"
          data-testid="image-holder"
          className="deal-card-image-box"
        >
          {isLoading ? (
            <DealCardImageSkeleton
              variant="rectangular"
              width={200}
              height={200}
              className="deal-card-image-skeleton"
            />
          ) : (
            <DealCardImage {...dealProps} />
          )}
        </Box>
        {renderDealCardContentComponent}
      </>
    );
  }, [
    isLoading,
    image240Width,
    image240Height,
    dealProps,
    renderDealCardContentComponent
  ]);

  return (
    <Box
      sx={{
        containerType: 'inline-size',
        position: 'relative',
        // borderBottom: '1px solid #e0e0e0',
        display: 'block'
        // '@container (max-width: 700px)': {
        // borderBottom: 'none !important'
        // }
      }}
      data-testid="deal-card-inline-size"
      maxWidth="800px"
      margin="0 auto"
    >
      {renderPreviewDiv}
      <Card
        elevation={0}
        data-testid="deal-card-inner-container"
        sx={{
          display: 'block',
          '@container (max-width: 700px)': {
            margin: '6px auto 0 auto'
          },
          margin: '16px auto 0 auto',
          borderRadius: '0px',
          paddingTop: '0px',
          filter: previewMode ? 'blur(3px)' : 'none',
          backgroundColor: 'transparent'
        }}
        className="deal-card-inner-container"
      >
        {renderDealCardContent}
      </Card>
    </Box>
  );
});

export default DealCard;
