import React from 'react';
import { Box, Button } from '@mui/material';
import { useSignUpModal } from '@hooks/useLoginModal';

const PreviewDiv = ({
  loginMessage,
  modalMessage
}: {
  loginMessage?: string;
  modalMessage?: string;
}) => {
  const { showNonLoggedInModal } = useSignUpModal();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.8)',
        zIndex: '1000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
        color: 'black'
      }}
    >
      <Button
        onClick={() => {
          showNonLoggedInModal({
            modalMessage: modalMessage || 'Please login to use this feature',
            featureName: 'mark-coupon-used'
          });
        }}
      >
        {loginMessage || 'Login'}
      </Button>
    </Box>
  );
};

export default PreviewDiv;
