/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation } from 'react-query';
import axios from '@utils/axios';

function useResendValidationEmail() {
  const mutationFn = async (email: string): Promise<void> => {
    await axios.post(`/api/resend-verification-email`, {
      email
    });
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('Error sending');
    }
  });
}

export default useResendValidationEmail;
