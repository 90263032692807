import config from '@configFile';
import { PageDealType } from '@types';
import { replaceNumLiveDeals } from './formatUtils';

export const getTitleFromPageDeal = (
  pageDeal: Pick<PageDealType, 'numLiveDeals' | 'title'> | null
): string => {
  const title = pageDeal?.title || '';
  return replaceNumLiveDeals(title, pageDeal?.numLiveDeals);
};

export const getDescriptionFromPageDeal = (
  pageDeal: Pick<PageDealType, 'numLiveDeals' | 'description'> | null
): string => {
  const description = pageDeal?.description || '';
  return replaceNumLiveDeals(description, pageDeal?.numLiveDeals);
};

export const getPageDealUrlPartFromSlug = (slug: string): string => {
  const url = getPageDealUrlFromSlug(slug, false);

  return url.replace('https://www.jungle.deals', '');
};

// Helper function to handle common logic
const getBasePageDealUrl = (
  slug: string,
  useLocal: boolean,
  suffix: string
): string => {
  const prefix = useLocal
    ? `${config.CURRENT_URL}`
    : 'https://www.jungle.deals';

  if (slug === 'best-subscribe-save-deals-amazon') {
    return `${prefix}/best-amazon-subscribe-save-deals`;
  }

  return `${prefix}${suffix}`;
};

// Specific function to get page deal URLs
export const getPageDealUrlFromSlug = (
  slug: string,
  useLocal = false
): string => {
  return getBasePageDealUrl(slug, useLocal, `/p/${slug}`);
};

// Specific function to get blog page deal URLs
export const getPageDealBlogUrlFromSlug = (
  slug: string,
  useLocal = false
): string => {
  return getBasePageDealUrl(slug, useLocal, `/blog/page-deal-${slug}`);
};

// Specific function to get blog page deal URLs
export const getCustomBlogPostUrl = (
  slug: string,
  useLocal = false
): string => {
  return getBasePageDealUrl(slug, useLocal, `/blog/${slug}`);
};
