import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import ClearIcon from '@mui/icons-material/Clear';

import {
  Box,
  Button,
  FormControl,
  TextField,
  IconButton,
  InputAdornment
} from '@mui/material';
import { removeParagraphIndentation } from '@utils/index';

const PostText = ({
  ASIN,
  additionalPostText,
  setAdditionalPostText,
  otherMailingType,
  getAIPostText,
  isLoadingAIPostText,
  isUpdating,
  updatePostText,
  prompt,
  setPrompt
}: {
  ASIN: string;
  additionalPostText: string;
  setAdditionalPostText: (value: string) => void;
  otherMailingType: string | null;
  getAIPostText: () => void;
  isLoadingAIPostText: boolean;
  isUpdating: boolean;
  updatePostText: () => void;
  prompt: string;
  setPrompt: (value: string) => void;
}) => {
  return (
    <Box>
      <FormControl margin="normal" fullWidth>
        <TextField
          multiline
          placeholder="Post text"
          rows={additionalPostText?.length < 10 && !otherMailingType ? 1 : 10}
          value={removeParagraphIndentation(additionalPostText)}
          onChange={(e) => setAdditionalPostText(e.target.value)}
          autoComplete="off"
        />
      </FormControl>
      <TextField
        multiline
        rows={2}
        id="prompt"
        label="Prompt"
        type="text"
        fullWidth
        variant="outlined"
        value={prompt}
        onChange={(e) => {
          setPrompt(e.target.value);
        }}
        sx={{
          marginTop: '12px'
        }}
        InputProps={{
          endAdornment:
            prompt.length > 0 ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear input"
                  onClick={() => setPrompt('')}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null
        }}
      />
      <Button onClick={getAIPostText}>
        {isLoadingAIPostText ? 'Loading...' : 'Get AI Post Text Suggestion'}
      </Button>
      {ASIN ? (
        <LoadingButton loading={isUpdating} onClick={updatePostText}>
          Update post text
        </LoadingButton>
      ) : null}
      <IconButton
        onClick={() => {
          setAdditionalPostText('');
        }}
      >
        <ClearIcon />
      </IconButton>
    </Box>
  );
};

export default PostText;
