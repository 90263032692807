import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useGetUserData } from '@hooks/useGetUserData';

interface DealSortSelectProps {
  sort: string;
  handleSortChange: (event: SelectChangeEvent) => void;
}

const DealSortSelect = ({ sort, handleSortChange }: DealSortSelectProps) => {
  const { data: userData } = useGetUserData();
  return (
    <Select
      labelId="sort-deals"
      value={sort}
      label="Sort"
      onChange={handleSortChange}
    >
      <MenuItem value="newest">Newest first</MenuItem>
      {userData?.isAdmin ? (
        <MenuItem value="oldest-active-first">Oldest first</MenuItem>
      ) : null}
      <MenuItem value="lowest">Lowest price first</MenuItem>
      <MenuItem value="highest">Highest price first</MenuItem>
      {userData?.hasVerifiedEmail ? (
        <MenuItem value="highest-selling">Most popular first</MenuItem>
      ) : (
        <MenuItem value="highest-selling" disabled>
          {userData
            ? 'Most popular first (verified users only)'
            : 'Most popular first (users only)'}
        </MenuItem>
      )}
      <MenuItem value="amazon-best-selling">Best selling on Amazon</MenuItem>
      <MenuItem value="highest-discount">
        Largest discount from list price first
      </MenuItem>
      <MenuItem value="highest-discount-non-list-price">
        Largest discount from current price first
      </MenuItem>
      <MenuItem value="highest-coupon-discount">
        Largest coupon discount first
      </MenuItem>
      {userData?.isAdmin ? (
        <MenuItem value="lowest-average-comparison">
          Average price lower
        </MenuItem>
      ) : null}

      {userData?.isAdmin ? (
        <MenuItem value="last-sold-first">Oldest by last sold date</MenuItem>
      ) : null}
      {userData?.isAdmin ? (
        <MenuItem value="newest-sold-first">Newest by last sold date</MenuItem>
      ) : null}
      {userData?.isAdmin ? (
        <MenuItem value="newest-added-first">Newest added</MenuItem>
      ) : null}
      {userData?.isAdmin ? (
        <MenuItem value="best-selling-by-sales-items">
          Most by total sales items
        </MenuItem>
      ) : null}
    </Select>
  );
};

export default DealSortSelect;
