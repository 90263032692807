/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import {
  useQuery,
  UseQueryResult,
  useMutation
  // useQueryClient
} from 'react-query';
import axios from '@utils/axios';
import { OCRImageData } from '@types';
import { useSnackbar } from '@hooks/useSnackbar';
import queryClient from '../queryClient';

export const useGetOCRImages = (
  ASIN: string
): UseQueryResult<OCRImageData[]> => {
  const queryOCRImages = async (ASIN: string): Promise<OCRImageData[]> => {
    const result = await axios.get<{
      data: OCRImageData[];
    }>(`/api/dashboard/get-ocr-for-image?ASIN=${ASIN}`);
    return result.data;
  };

  return useQuery<OCRImageData[], Error>(
    `ocr-images-${ASIN}`,
    () => queryOCRImages(ASIN),
    {
      onError: () => {
        console.error('Something went wrong while fetching the OCR images');
      }
    }
  );
};

interface RequestDataOCRBanned {
  ASIN: string;
  isBanned: boolean;
  image: string;
}

const mutationOCRBannedFn = async ({
  ASIN,
  isBanned,
  image
}: RequestDataOCRBanned): Promise<{
  data: {
    message: string;
  };
}> => {
  const data = axios.post<{
    data: {
      message: string;
    };
  }>(`/api/dashboard/set-ocr-image-banned`, {
    ASIN,
    isBanned,
    image
  });
  return data;
};

export const setOCRImageAsBanned = () => {
  const { showMessage } = useSnackbar();
  return useMutation(mutationOCRBannedFn, {
    onSuccess: ({ data: { message } }, { ASIN }) => {
      queryClient.invalidateQueries(`ocr-images-${ASIN}`);
      queryClient.invalidateQueries(`deal-summary-${ASIN}`);
      queryClient.refetchQueries(`deal-summary-admin-${ASIN}`);
      queryClient.invalidateQueries('latest-deals-db');
      showMessage(message);
    },
    onError: () => {
      console.error('error setting image');
    }
  });
};

// interface ResultData {
//   high_res_images: string;
// }

// const queryProductImages = async (ASIN: string): Promise<ResultData> => {
//   const result = await axios.get<{
//     data: {
//       high_res_images: string;
//     };
//   }>(`/api/dashboard/getProductImages?ASIN=${ASIN}`);
//   console.log('result', result);
//   return result.data;
// };

// export function useGetItemImages(ASIN: string): UseQueryResult<ResultData> {
//   return useQuery<ResultData, Error>(
//     `product-images-${ASIN}`,
//     () => queryProductImages(ASIN),
//     {
//       enabled: true,
//       onError: () => {
//         console.error('Something went wrong while fetching the product images');
//       }
//     }
//   );
// }

export function setItemImage() {
  interface RequestData {
    image: string;
    ASIN: string;
  }
  const { showMessage } = useSnackbar();
  const mutationFn = async ({ ASIN, image }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/setProductImage`, {
      ASIN,
      image
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (_res, { ASIN }) => {
      queryClient.invalidateQueries(`ocr-images-${ASIN}`);
      queryClient.invalidateQueries(`deal-summary-${ASIN}`);
      queryClient.refetchQueries(`deal-summary-admin-${ASIN}`);
      queryClient.invalidateQueries('latest-deals-db');
      showMessage('Image set successfully');
    },
    onError: () => {
      console.error('error setting image');
    }
  });
}
