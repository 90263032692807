/* eslint-disable camelcase, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Box,
  FormGroup,
  Typography,
  Switch,
  FormControlLabel,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import uniq from 'lodash/uniq';
import Loading from '@components/Loading';
import {
  // useGetItemImages,
  setItemImage,
  setOCRImageAsBanned,
  useGetOCRImages
} from '@hooks/useItemImages';
import { isImageBanned } from '@utils/index';
import { useGetDealSummaryAdminEnabled } from '@hooks/useGetDealSummary';

interface ImageSwitchingModalProps {
  ASIN: string;
  closeCallback: () => void;
}

const ImageSwitchingModal = ({
  ASIN,
  closeCallback
}: ImageSwitchingModalProps) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const { mutate: setItemImageMutation } = setItemImage();
  const { mutate: setOCRImageAsBannedMutation, isLoading: isLoadingSetOCR } =
    setOCRImageAsBanned();
  const { data: imageData, isLoading } = useGetDealSummaryAdminEnabled(ASIN);
  const { data: ocrImages, isLoading: areOCRImagesLoading } =
    useGetOCRImages(ASIN);

  const { highResImages = null } = imageData || {};
  const images = (highResImages && highResImages.split(',')) || [];

  const close = () => {
    closeCallback();
  };

  const change = () => {
    setItemImageMutation({
      ASIN,
      image: selectedImage
    });
    close();
  };

  const uniqueImages = uniq(images);

  const getOCRText = (image: string) => {
    const img = ocrImages?.find((img) => img.image === image);
    return img?.text;
  };

  const bannedImageStyles = {
    WebkitFilter: 'grayscale(100%)',
    filter: 'grayscale(100%)'
  };

  return (
    <Dialog open onClose={close} maxWidth="xl">
      <DialogTitle>Adjust Images</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="subtitle2" component="span">
            Change the default image for this product by selecting below
          </Typography>
        </DialogContentText>
        <Box>
          <FormGroup>
            <Box marginTop="12px">
              <Grid container spacing={3}>
                {isLoading ? <Loading /> : null}
                {uniqueImages.map((image: string) => (
                  <Grid item key={image} xs={6} sm={6} md={4} lg={3}>
                    <img
                      src={image?.replace('http:', 'https:')}
                      alt={`For ${ASIN}`}
                      style={{
                        maxHeight: '180px',
                        maxWidth: '180px',
                        margin: '5px',
                        border:
                          image === selectedImage ? '3px solid purple' : '',
                        ...(isImageBanned(image, ocrImages || [])
                          ? bannedImageStyles
                          : {})
                      }}
                      onClick={() => setSelectedImage(image)}
                    />
                    <Box display="block">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isImageBanned(image, ocrImages || [])}
                            disabled={areOCRImagesLoading || isLoadingSetOCR}
                            onChange={() => {
                              setOCRImageAsBannedMutation({
                                ASIN,
                                image,
                                isBanned: !isImageBanned(image, ocrImages || [])
                              });
                            }}
                          />
                        }
                        label="Remove from UI"
                      />
                    </Box>
                    <Box>
                      <textarea
                        value={getOCRText(image)}
                        style={{ width: '100%', height: '100px' }}
                        readOnly
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={change}
          variant="contained"
          disabled={selectedImage === null}
        >
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ImageSwitchingModalButton = ({
  ASIN,
  closeCallback
}: ImageSwitchingModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAdminSetImageClick = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    closeCallback();
  };

  return (
    <>
      {isOpen && <ImageSwitchingModal ASIN={ASIN} closeCallback={close} />}
      <MenuItem key="admin-set-image" onClick={handleAdminSetImageClick}>
        <ListItemIcon>
          <ImageIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Adjust Images</ListItemText>
      </MenuItem>
    </>
  );
};
