import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Divider,
  CircularProgress
} from '@mui/material';
import { useLocation } from 'react-router';
import { useTheme } from '@mui/system';
import config from '@configFile';

import { useGetUserData } from '@hooks/useGetUserData';
import trackUse from '@utils/trackUse';
import { AppState } from '@types';
import {
  logPostHogEvent,
  getPostPrefix,
  getPostPrice,
  getProductLink
} from '@utils/index';
import RedeemStepsList from '@components/RedeemStepsList';
import DealPostSummary from '@pages/Blog/components/DealPostFeedCard/DealPostSummary';
// import EmailSignUp from '@components/EmailSignUp';
import SubscribeSaveTip from '@components/SubscribeSaveTip';
import MonetizingLink from '../MonetizingLink';
import Signin from '../Signin';
import DealEditorsNotes from '../DealEditorsNotes';
import PosterDetails from '../PosterDetails';
// import CurrentAmazonSpecialOffers from '../CurrentSpecialOffers';
import MailingHistory from '../MailingHistory';
import AddProduct from '../AdminActions/AddVariation';
import Disclaimer from '../Disclaimer';
// import CompareTo from '../CompareTo';
import CouponDeals from './components/CouponDeals';
import DiscountReceipt from './components/DiscountReceipt';
import BrandDeals from './components/BrandDeals';
// import ListDeals from './components/ListDeals';
import TopDeals from './components/TopDeals';

const topModalRef = React.createRef<HTMLDivElement>();

type Props = ReturnType<typeof mapStateToProps>;

const DealSummary: React.FC<Props> = ({
  dealSummary,
  dealSummaryError,
  dealSummaryASIN
}) => {
  const location = useLocation();
  const theme = useTheme();
  const { data: user } = useGetUserData();
  const [mainImage, setMainImage] = useState(dealSummary?.image);
  const highResImages = (dealSummary?.highResImages || '').split(',');
  const uniqueArrayHighResImages = highResImages.filter(
    (value, index) => highResImages.indexOf(value) === index
  );
  const tag = config.AFFILIATE_TAGS.PRODUCT_PAGE;
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (dealSummary) {
      setMainImage(dealSummary.image);

      topModalRef?.current?.scrollIntoView({
        behavior: 'smooth'
      });

      trackUse({
        item: 'deal-summary',
        value: `https://www.jungle.deals/deal/${dealSummary.ASIN}${
          dealSummary?.title ? ` — ${dealSummary?.title}` : ''
        }`,
        type: 'VIEW'
      });

      logPostHogEvent('deal-summary-page-view', {
        title: dealSummary?.title,
        ASIN: dealSummary?.ASIN,
        type: 'VIEW'
      });
    }
  }, [dealSummary]);

  const handleThumbnailClick = (image: string) => {
    setMainImage(image);
  };

  if (dealSummaryError) {
    trackUse({
      item: `deal-summary-404`,
      value: dealSummaryASIN,
      type: 'ERROR'
    });

    // return loading spinner
    return (
      <Box>
        {!!user?.isAdmin && searchParams.get('threshold') && dealSummaryASIN ? (
          <Box display="flex" justifyContent="flex-end">
            <AddProduct
              ASIN={dealSummaryASIN}
              threshold={
                searchParams.get('threshold')
                  ? parseFloat(searchParams.get('threshold') as string)
                  : 0
              }
            />
          </Box>
        ) : null}
        <Box display="flex" justifyContent="center" height="300px">
          <Typography variant="body1">
            We were not able to find this deal in our archives.
          </Typography>
          <MonetizingLink
            style={{ marginLeft: '8px' }}
            href={getProductLink(dealSummaryASIN, tag)}
            target="_blank"
            tag={tag}
            clickType="deal-summary-404"
            underline="always"
          >
            See it on Amazon.com
          </MonetizingLink>
        </Box>
      </Box>
    );
  }

  if (!dealSummary) {
    // return loading spinner
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const productURL = getProductLink(dealSummary?.ASIN, tag);

  return (
    <>
      <Box>
        <div ref={topModalRef} />
        <Disclaimer />
        <PosterDetails />
        {!!user?.isAdmin && dealSummary?.ASIN ? (
          <MailingHistory filterTests ASIN={dealSummary.ASIN} />
        ) : null}
        <Typography
          component="h2"
          variant="subtitle2"
          sx={{
            marginBottom: '12px',
            '@media (max-width: 540px)': {
              fontSize: '14px'
            },
            fontSize: '17px'
          }}
        >
          <MonetizingLink
            href={productURL}
            target="_blank"
            sx={{
              color: theme.palette.linkColor,
              fontWeight: 'bold'
            }}
            underline="none"
            tag={tag}
            clickType="deal-summary-product-link"
          >
            {`${getPostPrefix()}${dealSummary.title}`}
          </MonetizingLink>{' '}
          {getPostPrice(dealSummary, true)}
        </Typography>
        <Card style={{ display: 'flex', justifyContent: 'space-between' }}>
          <MonetizingLink
            href={productURL}
            clickType="how-to-get-deal-summary"
            tag={tag}
            style={{
              flexGrow: 1
            }}
          >
            <CardMedia
              component="img"
              height="300"
              image={mainImage}
              alt={dealSummary.title}
              style={{
                objectFit: 'contain'
              }}
            />
          </MonetizingLink>
          {(uniqueArrayHighResImages || []).length > 1 && (
            <Box
              display="flex"
              flexDirection="column"
              overflow="auto"
              style={{
                maxHeight: '300px',
                maxWidth: '100px',
                minWidth: '90px',
                overflowY: 'scroll',
                flex: '0 0 auto'
              }}
            >
              {uniqueArrayHighResImages.map((img, index) => (
                <button
                  key={img}
                  type="button"
                  onClick={() => handleThumbnailClick(img)}
                  onKeyUp={(e) => {
                    console.log(e, e.key);
                    // Check for the "Enter" key
                    if (e.key === 'Enter') {
                      handleThumbnailClick(img);
                    }
                  }}
                  style={{
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    padding: 0,
                    margin: 0,
                    minWidth: '90px',
                    minHeight: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden'
                  }}
                >
                  <img
                    src={img?.replace('http:', 'https:')}
                    alt={`Thumbnail ${index + 1}`}
                    style={{
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </button>
              ))}
            </Box>
          )}
        </Card>
        <DealPostSummary
          deal={dealSummary}
          tag={tag}
          isExpired={!!dealSummary?.isExpired}
        />
        {dealSummary.ss ? <SubscribeSaveTip /> : null}
        <RedeemStepsList deal={dealSummary} />
        <DiscountReceipt receiptDeal={dealSummary} tag={tag} />
        <DealEditorsNotes deal={dealSummary} tag={tag} />
        <Divider
          sx={{
            display: user ? 'none' : 'block',
            margin: '24px 0'
          }}
        />
        <Box
          sx={{
            display: user ? 'none' : 'block',
            maxWidth: '450px',
            margin: '16px auto 0 auto'
          }}
        >
          {/* <CompareTo deal={dealSummary} /> */}
          <Signin showInCard />
        </Box>
        {dealSummary?.promoCode && (
          <>
            <Divider style={{ margin: '12px 0' }} />
            <CouponDeals
              promoCode={dealSummary.promoCode}
              currentASIN={dealSummary.ASIN}
            />
          </>
        )}

        {dealSummary?.brand && (
          <BrandDeals
            brand={dealSummary.brand}
            currentASIN={dealSummary.ASIN}
          />
        )}

        <TopDeals />
      </Box>
    </>
  );
};

const mapStateToProps = ({
  home: { dealSummary, dealSummaryASIN, dealSummaryError }
}: AppState) => {
  return {
    dealSummaryASIN,
    dealSummary,
    dealSummaryError
  };
};

export default connect(mapStateToProps)(DealSummary);
