import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import { useGetTopDeals } from '@hooks/useTopDeals';
import CommonAccordion from '@components/CommonAccordion';
import config from '@configFile';

const TopDeals = () => {
  const { data: topDeals, isLoading } = useGetTopDeals();

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        marginTop="24px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!topDeals) {
    return null;
  }

  return (
    <CommonAccordion
      title="Most Popular Deals of the Week"
      deals={topDeals}
      currentASIN="" // No need to exclude a specific ASIN here
      affiliateTag={config.AFFILIATE_TAGS.JD_TOP_DEALS}
    />
  );
};

export default TopDeals;
