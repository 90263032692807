import React from 'react';
import ShowIcon from '@components/ShowIcon';

const strategyIds = [
  {
    label: 'Everyday Essentials',
    value: 'everyday-essentials-default',
    icon: 'KitchenIcon'
  },
  {
    label: `Most Loved`,
    value: 'cml',
    icon: 'WorkspacePremiumIcon'
  },
  {
    label: 'Off to College',
    value: 'off-to-college',
    icon: 'SchoolIcon'
  },
  {
    label: 'Popular Brands',
    value: 'popular-brands',
    icon: 'WhatshotIcon'
  },
  {
    label: 'Internet Famous',
    value: 'internet-famous',
    icon: 'TagIcon'
  },
  {
    label: 'Gift Guide - Adults',
    value: 'gifting-unisex-adult',
    icon: 'IoGiftSharp',
    link: '/p/gift-guide-adults-amazon-deals',
    title: 'Amazon Deals on Gifts for Adults',
    slug: 'gift-guide-adults-amazon-deals'
  },
  {
    label: 'Gift Guide - Teen',
    value: 'gifting-unisex-teen',
    icon: 'CardGiftcardIcon',
    link: '/p/deals-on-gifts-for-teenagers',
    title: 'Amazon Deals on Gifts for Teenagers',
    slug: 'deals-on-gifts-for-teenagers'
  },
  {
    label: 'Gift Guide - Kids',
    value: 'gifting-unisex-kid',
    icon: 'ToysIcon',
    link: '/p/deals-on-gifts-for-kids',
    title: 'Amazon Deals on Gifts for Kids',
    slug: 'deals-on-gifts-for-kids'
  },
  {
    label: 'White Elephant',
    value: 'white-elephant',
    icon: 'GiElephant',
    link: '/p/deals-white-elephant-gifts',
    title: 'Amazon Deals on White Elephant Gifts',
    slug: 'deals-white-elephant-gifts'
  },
  {
    label: 'Stocking Stuffers - Adults',
    value: 'stocking-stuffers-unisex-adult',
    icon: 'PiSockFill',
    link: '/p/stocking-stuffers-adults',
    title: 'Amazon Deals on Stocking Stuffers for Adults',
    slug: 'stocking-stuffers-adults'
  },
  {
    label: 'Stocking Stuffers - Teen',
    value: 'stocking-stuffers-unisex-teen',
    icon: 'PiSockFill',
    link: '/p/stocking-stuffers-teens',
    title: 'Amazon Deals on Stocking Stuffers for Teens',
    slug: 'stocking-stuffers-teens'
  },
  {
    label: 'Stocking Stuffers - Kids',
    value: 'stocking-stuffers-kids',
    icon: 'PiSockFill',
    link: '/p/stocking-stuffers-kids',
    title: 'Amazon Deals on Stocking Stuffers for Kids',
    slug: 'stocking-stuffers-kids'
  },
  {
    label: 'New Parents',
    value: 'new-parents',
    icon: 'ChildFriendlyIcon'
  },
  {
    label: 'Holiday Decor',
    value: 'holiday-decor',
    icon: 'TbChristmasBall'
  },
  {
    label: `Favorite Gifts`,
    value: 'customers-favorite-gifts',
    icon: 'HeartIcon'
  },
  {
    label: 'Premium Brands',
    value: 'premium-brands',
    icon: 'WorkspacePremiumIcon'
  },
  {
    label: 'Back to School',
    value: 'back-to-school-none',
    icon: 'SchoolIcon'
  },
  {
    label: 'Back to School (PreK-2)',
    value: 'back-to-school-prek-2',
    icon: 'SchoolIcon'
  },

  {
    label: 'Winter Favorites',
    value: 'winter-favorites',
    icon: 'AcUnitIcon'
  },
  {
    label: 'Back to School (7-8)',
    value: 'back-to-school-7-8',
    icon: 'SchoolIcon'
  },
  {
    label: 'Back to School (3-6)',
    value: 'back-to-school-3-6',
    icon: 'SchoolIcon'
  },

  {
    label: 'Back to School (9-12)',
    value: 'back-to-school-9-12',
    icon: 'SchoolIcon'
  }
];

export const holidayStrategyIdLinks = strategyIds
  .filter((strategyId) => strategyId.link && strategyId.icon)
  .map((strategyId) => {
    return {
      ...strategyId,
      name: strategyId.label,
      icon: (
        <ShowIcon
          icon={strategyId.icon}
          style={{
            fontSize: '24px'
          }}
        />
      )
    };
  });

export default strategyIds;
