import React from 'react';
import trackUse from '@utils/trackUse';
import { getASINFromURL } from '@utils/URL';

export const trackLinkClick = (
  e: React.MouseEvent<HTMLAnchorElement>,
  location: string
) => {
  trackUse({
    item: location,
    value: e.currentTarget.getAttribute('href'),
    type: 'CLICK'
  });
};

export const logPostHogEvent = (
  eventName: string,
  extraItems?: Record<string, any>
) => {
  // @ts-ignore
  if (window?.posthog) {
    // @ts-ignore
    window?.posthog?.capture(eventName, {
      $referrer: document?.referrer, // Where the user came from
      $current_url: window?.location?.href, // Current page URL
      $browser: navigator?.userAgent, // User's browser details
      $screen_resolution: `${window?.screen?.width}x${window?.screen?.height}`, // Screen resolution
      $timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone, // User's timezone
      $platform: navigator?.platform, // OS platform (e.g., MacIntel, Win32)
      $language: navigator?.language, // Browser language
      $device_type: window?.navigator?.userAgent?.includes('Mobile')
        ? 'mobile'
        : 'desktop', // Device type
      // @ts-ignore
      connection_type: navigator?.connection?.effectiveType || 'unknown', // e.g., '4g', 'wifi'
      ...(extraItems || {})
    });
  }
};

export const logPostHogClick = (
  href: string,
  clickType: string,
  tag: string,
  item: string,
  extraItems?: Record<string, any>
) => {
  // @ts-ignore
  if (window?.posthog) {
    // @ts-ignore
    window?.posthog?.capture('monetizing_click', {
      ASIN: getASINFromURL(href) || '',
      tag,
      clickType,
      value: href,
      type: 'CLICK',
      item: item || clickType || tag,
      ...(extraItems || {})
    });
  }
};
