/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation } from 'react-query';
import axios from '@utils/axios';
import { useSnackbar } from '@hooks/useSnackbar';
import queryClient from '../../queryClient';

export function useMarkCouponAsRead() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    promoCode: string;
    ASIN: string;
    newState: boolean;
  }

  const mutationFn = async ({
    ASIN,
    promoCode
  }: RequestData): Promise<null> => {
    return axios.post(`/api/mark-coupon-used`, {
      ASIN,
      promoCode
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (_data, { newState }) => {
      queryClient.refetchQueries('user-data');
      showMessage(
        newState ? 'Coupon marked as used' : 'Coupon marked as unused'
      );
    },
    onError: () => {
      console.error('error marking coupon as used');
    }
  });
}
