import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useTheme,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DealCard from '@components/DealCard';

interface CommonAccordionProps {
  title: string;
  deals: any[];
  currentASIN: string;
  defaultExpanded?: boolean;
  affiliateTag: string;
}

const CommonAccordion = ({
  title,
  deals,
  currentASIN,
  defaultExpanded = true,
  affiliateTag
}: CommonAccordionProps) => {
  const theme = useTheme();

  const filteredDeals = deals.filter((deal) => deal.ASIN !== currentASIN);

  if (filteredDeals.length === 0) {
    return null;
  }

  return (
    <Box marginTop="24px">
      <Accordion
        defaultExpanded={defaultExpanded}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '4px',
          background:
            theme.palette.mode === 'dark'
              ? theme.palette.grey[800]
              : theme.palette.grey[100]
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color:
                  theme.palette.mode === 'dark'
                    ? 'white'
                    : theme.palette.text.primary
              }}
            />
          }
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.palette.grey[900]
                : theme.palette.grey[200],
            borderRadius: '4px',
            '&:hover': {
              background: theme.palette.action.hover
            }
          }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              color:
                theme.palette.mode === 'dark'
                  ? 'white'
                  : theme.palette.text.primary,
              fontWeight: 'bold'
            }}
            variant="subtitle2"
          >
            {title} ({filteredDeals.length} deal
            {filteredDeals.length > 1 ? 's' : ''})
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(2),
            borderTop: `1px solid ${theme.palette.divider}`
          }}
        >
          {filteredDeals.map((deal) => (
            <DealCard
              key={deal.ASIN}
              shouldLazyLoad={false}
              {...deal}
              tag={affiliateTag}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CommonAccordion;
