import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
// import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import appConfig from '@configFile';

class ApiClient {
  private client: AxiosInstance;

  constructor(baseURL: string) {
    this.client = axios.create({
      baseURL,
      withCredentials: true,
      timeout: 30000 // 30 seconds timeout
    });

    this.client.interceptors.request.use((config) => {
      const timestamp = new Date().toISOString();
      const requestId = uuidv4(); // Generate a unique request ID

      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers, // Preserve existing headers
        // Authorization: `Bearer ${process.env.API_TOKEN}`, // Replace with your auth token logic
        'Content-Type': 'application/json',
        'X-Request-ID': requestId,
        'X-Correlation-ID': requestId, // Same as Request-ID if no external correlation ID
        'X-Timestamp': timestamp,
        'Accept-Language': navigator.language || 'en-US', // For browser-based apps
        'X-Timezone': Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone, // User's time zone
        'X-Client-Version': appConfig.version, // Replace with dynamic version
        'X-Env': process.env.NODE_ENV || 'development',
        'X-Origin': 'frontend' // Or another service name
      };

      return config;
    });

    this.client.interceptors.response.use(
      (response) => this.wrapResponse(response),
      (error) => Promise.reject(error)
    );
  }

  // Wrap the response
  // eslint-disable-next-line class-methods-use-this
  private wrapResponse<T>(response: any): { data: T } {
    return { data: response };
  }

  // Generic request method
  public async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const response = await this.client.request<T>(config);
      return response.data;
    } catch (error: any) {
      console.error('Request failed:', error.response?.data || error.message);
      throw error;
    }
  }

  // Convenience methods for different HTTP methods
  public async post<T>(url: string, data: Record<string, any>): Promise<T> {
    return this.request<T>({ method: 'POST', url, data });
  }

  public async get<T>(url: string, params?: Record<string, any>): Promise<T> {
    return this.request<T>({ method: 'GET', url, params });
  }

  public async delete<T>(
    url: string,
    params?: Record<string, any>
  ): Promise<T> {
    return this.request<T>({ method: 'DELETE', url, params });
  }
}

const apiClient = new ApiClient(appConfig.API_PREFIX);
export default apiClient;
