import React from 'react';
import times from 'lodash/times';
import { connect, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@mui/material/Typography';
import { Grid, Skeleton, Divider, useMediaQuery } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router';
// import Loading from '@components/Loading';

import { useTheme } from '@mui/system';
import { AppState, DEALS_UPDATE_RECEIPT_DEAL } from '@types';
import Disclaimer from '@components/Disclaimer';
import ScrollTop from '@components/ScrollTop';

// import SignupForm from '@pages/Blog/components/SignUpForm';
// import config from '@configFile';
// import { useGetUserData } from '@hooks/useGetUserData';
// import { useGetUserMailingLists } from '@hooks/useEmail';
import { getCurrentDealEventStrObj } from '@utils/index';
import { useDealsState } from './useDealsState';
import DealSortSelect from './components/DealSortSelect';
import DealCard from './components/DealCard';
// import DatabaseCard from './components/DatabaseCard';
import SkeletonDealCard from './components/SkeletonDealCard';
import SkeletonDatabaseCard from './components/SkeletonDatabaseCard';
import ReceiptDealContainer from './components/ReceiptDealContainer';
import DatabaseEndMessage from './components/DatabaseEndMessage';
import SearchFilterField from './components/SearchFilterField';
import FilterChips from './components/FilterChips';
import DatabaseSettings from './components/DatabaseSettings';
import dealPageConfig from './defaultConfig';
import StrategyChips from './components/StrategyChips';

// const emailSignUpWrapperStyles = {
//   width: '100%',
//   margin: {
//     xs: '0px 12px',
//     sm: '0px auto'
//   }
// };

interface DealsProps extends ReturnType<typeof mapStateToProps> {
  isEventDay?: boolean;
}

const Deals = (props: DealsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { data: mailingListsData } = useGetUserMailingLists();
  // const { data: user } = useGetUserData();
  // const isAdmin = !!user?.isAdmin;
  const { dealSummaryASIN, dealSummary, isEventDay } = props;
  const funcs = useDealsState(isEventDay, dealSummaryASIN);
  const currentEventObj = getCurrentDealEventStrObj();
  const theme = useTheme();

  // const mailingLists = mailingListsData || [];
  // const isSubscribedToDailyDeals = useMemo(() => {
  //   return mailingLists.includes(config.sendGrid.mailingLists.dailyDeals);
  // }, [mailingLists]);
  // const isSubscribedToHotDeals = useMemo(() => {
  //   return mailingLists.includes(config.sendGrid.mailingLists.hotDeals);
  // }, [mailingLists]);

  const {
    sort,
    onlyCoupons,
    isFiltering,
    setIsFiltering,
    searchFilter,
    setSearchFilter,
    strategyFilter,
    setStrategyFilter,
    amount,
    dealsWithASIN,
    latestDealsLoading,
    shouldShowV2Layout,
    tagData,
    unfilteredDealsWithASIN,
    toggleOnlyCoupons,
    resetAll,
    loadMore,
    handleSortChange,
    hasFilters
  } = funcs;

  const isDarkMode = theme.palette.mode === 'dark';

  const isLessThanThreeCardWidth = useMediaQuery('(max-width: 1189px)');
  // const CardComponent = shouldShowV2Layout ? DatabaseCard : DealCard;
  const CardComponent = DealCard;
  const infiniteContainerStyles = {
    marginLeft: '-17px',
    marginRight: '-17px',
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    overflow: 'auto',
    paddingBottom: '12px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch'
  } as React.CSSProperties;
  const backgroundColor = isDarkMode ? '#212121' : '#dcdbdf';

  // dealItems.push(<SignupForm key="signup" />);

  const dealItems = React.useMemo(() => {
    return (dealsWithASIN || [])
      .map((props, i) => (
        <CardComponent
          {...props}
          index={i}
          key={`${props.title}_${props.ASIN}`}
          toggleOnlyCoupons={toggleOnlyCoupons}
          onlyCoupons={onlyCoupons}
          tag={tagData}
        />
      ))
      .slice(0, amount);
  }, [dealsWithASIN, amount, toggleOnlyCoupons, onlyCoupons, tagData]);

  // // put <Signup form in the 25th position
  // if (dealItems.length >= 24 && !isSubscribedToHotDeals) {
  //   dealItems.splice(
  //     24,
  //     0,
  //     <Box sx={emailSignUpWrapperStyles} key="signup-hot-deals">
  //       <SignupForm customPrompt="Sign up to our Hot Deals email list to make sure you don't miss any deals!" />
  //     </Box>
  //   );
  // }

  // const secondPosition = isSubscribedToHotDeals ? 24 : 49;
  // // put <Signup form in the 25th position
  // if (dealItems.length >= secondPosition && !isSubscribedToDailyDeals) {
  //   // this is 49 due to the sign up item above
  //   dealItems.splice(
  //     secondPosition,
  //     0,
  //     <Box sx={emailSignUpWrapperStyles} key="signup-daily-deals">
  //       <SignupForm
  //         customTitle="Get a round up of new deals once a day at 6am PST"
  //         mailingList={config.sendGrid.mailingLists.dailyDeals}
  //         customPrompt="Sign up to our Daily Deals email list to get a daily round up of the BEST Amazon deals"
  //       />
  //     </Box>
  //   );
  // }

  // uncomment to test the loading state
  // dealItems.unshift(<SkeletonDealCard key="test"/>);

  const renderReceiptDealContainer = () => {
    if (dealSummaryASIN) {
      return (
        <ReceiptDealContainer
          dealSummary={dealSummary}
          dealSummaryASIN={dealSummaryASIN}
          dealsWithASIN={dealsWithASIN}
          handleReceiptDealClose={() => {
            dispatch({
              type: DEALS_UPDATE_RECEIPT_DEAL,
              receiptDeal: null
            });

            history.push('/');
          }}
          tag={tagData}
        />
      );
    }

    return null;
  };

  const SkeletonComponent = shouldShowV2Layout
    ? SkeletonDatabaseCard
    : SkeletonDealCard;

  const renderLoader = () => {
    return (
      <>
        {times(
          amount === dealPageConfig.initialNumDeals
            ? dealPageConfig.initialLoadingSkeletons
            : dealPageConfig.loadingSkeletons,
          (i) => (
            <SkeletonComponent
              key={i}
              index={i}
              shouldRenderLoadingMessage={latestDealsLoading}
              targetLoadingIndex={isLessThanThreeCardWidth ? 0 : 1}
            />
          )
        )}
      </>
    );
  };

  const renderPreloader = () => {
    return (
      <Box
        data-testid="preloader"
        sx={{
          backgroundColor,
          marginLeft: '-16px',
          marginRight: '-17px'
        }}
      >
        <Box margin="10px 16px 10px 17px" data-testid="preloader-box">
          <Box
            sx={{
              height: 'auto',
              overflow: 'hidden scroll',
              marginLeft: '-17px',
              marginRight: '-17px',
              display: 'flex',
              flexWrap: 'wrap',
              paddingBottom: '12px'
            }}
            data-testid="preloader-box-inner"
          >
            {renderLoader()}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderInfiniteDeals = () => {
    return (
      <Box
        sx={{
          backgroundColor,
          marginLeft: '-16px',
          marginRight: '-17px'
        }}
      >
        <Box margin="10px 16px 10px 17px">
          <InfiniteScroll
            style={infiniteContainerStyles}
            dataLength={dealItems.length} // This is important field to render the next data
            next={() => loadMore()}
            hasMore={
              (dealsWithASIN.length > amount && !latestDealsLoading) ||
              latestDealsLoading
            }
            loader={renderLoader()}
            endMessage={
              <DatabaseEndMessage
                resetAll={resetAll}
                isFiltered={hasFilters()}
              />
            }
          >
            {dealItems}
          </InfiniteScroll>
        </Box>
      </Box>
    );
  };

  const renderSortDeals = () => {
    return (
      <Grid item xs={12} sm={6}>
        <FormControl
          fullWidth
          sx={{
            margin: '10px 4px 0px 4px !important',
            width: 'calc(100% - 8px) !important'
          }}
          size="small"
        >
          <InputLabel id="sort-deals">Sort</InputLabel>
          <DealSortSelect sort={sort} handleSortChange={handleSortChange} />
        </FormControl>
      </Grid>
    );
  };

  return (
    <>
      {renderReceiptDealContainer()}
      <Disclaimer />
      <Box marginLeft="-8px">
        <Grid container>
          {renderSortDeals()}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              alignItems: 'end',
              display: 'flex'
            }}
          >
            <SearchFilterField
              setIsFiltering={setIsFiltering}
              dealsWithASIN={dealsWithASIN}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
            />
            <DatabaseSettings />
          </Grid>
        </Grid>
      </Box>
      <FilterChips
        funcs={{
          ...funcs
        }}
        latestDealsLoading={latestDealsLoading}
        currentlyShowingDeals={unfilteredDealsWithASIN}
      />
      {currentEventObj?.shouldShowStrategyChips ? (
        <>
          <Divider
            sx={{
              display: {
                xs: 'flex',
                sm: 'none'
              },
              marginBottom: '12px'
            }}
          />
          <StrategyChips
            currentlyShowingDeals={unfilteredDealsWithASIN}
            strategyFilter={strategyFilter}
            setStrategyFilter={setStrategyFilter}
            isLoading={latestDealsLoading}
          />
        </>
      ) : null}
      {(isFiltering || hasFilters()) &&
        dealsWithASIN?.length > 0 &&
        unfilteredDealsWithASIN?.length > 0 && (
          <Box marginTop="4px">
            <Typography variant="caption">{`Showing ${dealsWithASIN?.length?.toLocaleString()} of ${unfilteredDealsWithASIN?.length?.toLocaleString()} deals...`}</Typography>
          </Box>
        )}
      {(isFiltering || hasFilters()) &&
        (dealsWithASIN || []).length === 0 &&
        (unfilteredDealsWithASIN || []).length === 0 &&
        latestDealsLoading && (
          <Box marginTop="4px">
            <Skeleton height="16px" width="166px" />
          </Box>
        )}
      {latestDealsLoading ? renderPreloader() : renderInfiniteDeals()}
      <ScrollTop />
    </>
  );
};

const mapStateToProps = ({
  home: { receiptDeal, dealSummaryASIN, dealSummary }
}: AppState) => ({
  receiptDeal,
  dealSummaryASIN,
  dealSummary
});

export default connect(mapStateToProps)(Deals);
