import React, { useMemo } from 'react';
import SignupForm from '@pages/Blog/components/SignUpForm';
import { useGetUserMailingLists } from '@hooks/useEmail';
import { getMailingListById } from '@utils/mailingLists';

const EmailSignUp = ({
  mailingListId,
  showCard = false
}: {
  mailingListId: string;
  showCard?: boolean;
}) => {
  const { data: mailingListsData } = useGetUserMailingLists();
  const mailingLists = mailingListsData || [];
  const mailingList = getMailingListById(mailingListId);

  const hideMailingListSignUp = useMemo(() => {
    // if no mailingListId then hide the mailing list sign up
    if (!mailingListId) {
      return true;
    }

    const isSubscribedToMailingList = mailingLists.includes(mailingListId);

    return !!isSubscribedToMailingList;
  }, [mailingLists, mailingListId]);

  if (hideMailingListSignUp) {
    return null;
  }

  return (
    <>
      <SignupForm
        customTitle={mailingList.signUpPrompt.title}
        mailingList={mailingListId}
        customPrompt={mailingList.signUpPrompt.subtitle}
        showCard={showCard}
      />
    </>
  );
};

export default EmailSignUp;
