import React from 'react';
import CommonAccordion from '@components/CommonAccordion';
import { useGetBrandDeals } from '@hooks/useGetBrandDeals';
import config from '@configFile';

const BrandDeals = ({
  currentASIN,
  brand
}: {
  currentASIN: string;
  brand: string;
}) => {
  const { data: deals, isLoading } = useGetBrandDeals(brand);

  if (isLoading || !deals) {
    return null;
  }

  return (
    <CommonAccordion
      title={`More deals from ${brand}`}
      deals={deals}
      currentASIN={currentASIN}
      affiliateTag={config.AFFILIATE_TAGS.JD_BRAND}
    />
  );
};

export default BrandDeals;
