/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult } from 'react-query';
import axios from '@utils/axios';
import { DealPostType } from '../types';

const queryBrandDeals = async (brand: string): Promise<DealPostType[]> => {
  const result = await axios.get<{
    data: DealPostType[];
  }>(`/api/get-brand-deals/${brand}`);
  return result.data;
};

export function useGetBrandDeals(
  brand: string
): UseQueryResult<DealPostType[]> {
  return useQuery<DealPostType[], Error>(
    `brand-deals-${brand}`,
    () => queryBrandDeals(brand),
    {
      onError: () => {
        console.error('Something went wrong while fetching the brand deals');
      }
    }
  );
}
