/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation } from 'react-query';
import axios from '@utils/axios';

function useReportInaccurate() {
  interface RequestData {
    message: string;
    ASIN: string;
  }

  const mutationFn = async ({ ASIN, message }: RequestData): Promise<void> => {
    await axios.post('/api/report-inaccurate', {
      ASIN,
      message,
      currentUrl: window?.location?.href
    });
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error reporting deal');
    }
  });
}

export default useReportInaccurate;
