//

/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation, useQuery, UseQueryResult } from 'react-query';
import axios from '@utils/axios';
import { PageDeal } from '@api/database/models/pageDeal';
import queryClient from '../queryClient';

export function useRefreshPageDeal() {
  const mutationFn = async (slug: string): Promise<void> => {
    const result = await axios.post<{
      data: null;
    }>(`/api/dashboard/page-deals/refresh-by-slug`, {
      slug
    });
    return result.data;
  };

  return useMutation(mutationFn, {
    onSuccess: (_res, slug) => {
      queryClient.refetchQueries('page-deal-slugs');
      queryClient.refetchQueries('page-deals');
      queryClient.refetchQueries(`page-deal-${slug}`);
    },
    onError: () => {
      console.error('Error refreshing');
    }
  });
}

export function useGetSocialPageDeals(): UseQueryResult<PageDeal[]> {
  const queryFn = async (): Promise<PageDeal[]> => {
    const result = await axios.get<{
      data: PageDeal[];
    }>(`/api/get-social-page-deals`);
    return result.data;
  };

  return useQuery<PageDeal[], Error>(`social-page-deals`, () => queryFn(), {
    onError: () => {
      console.error('Something went wrong while fetching the site metrics');
    }
  });
}
