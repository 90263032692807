export default {
  dealCardActions: {
    top: '-4px',
    right: 0,
    position: 'absolute',
    '@container (min-width: 700px)': {
      borderRadius: 'initial',
      backgroundColor: 'transparent',
      position: 'absolute',
      top: '12px !important',
      right: '0px !important',
      bottom: 'initial !important'
    }
  },
  actionsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',

    '@container (min-width: 700px)': {
      flexDirection: 'column-reverse'
    }
  },
  actionsBoxInner: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '@container (min-width: 700px)': {
      flexDirection: 'column-reverse'
    }
  }
};
