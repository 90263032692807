const UPDATE_CONFIG = {
  RECHECK_TIME: '2020-04-20 04:20:00',
  INACCURATE_RECHECK_TIME: '2001-04-20 04:20:00',
  TIERS: [
    {
      name: 'Tier 1',
      /*
         Will only be recycled if sold again with the update tier or
      */
      shouldBePurchasedAfterDateFirstActive: false,
      /*
        Will update if less than this percentage of the average
        */
      // 10% lower than average
      percentageAverageLowerToUpdate: 0.1,
      /*
       In tiers, these are the minimum days between a post once again being recycled to the top of the site.
       */
      updateDays: 55,
      /*
        If the product expires and then comes back in stock we should consider that by rewinding. Given a rewind
         time we remove this many days from the post being expired.
      */
      expiredRewind: 7,
      /*
        The threshold to be in this tier
      */
      salesThreshold: 750
    },
    {
      name: 'Tier 2',
      shouldBePurchasedAfterDateFirstActive: false,
      updateDays: 65,
      expiredRewind: 9,
      // 10% lower than average
      percentageAverageLowerToUpdate: 0.2,
      salesThreshold: 350
    },
    {
      name: 'Tier 3',
      shouldBePurchasedAfterDateFirstActive: false,
      updateDays: 108,
      expiredRewind: 15,
      salesThreshold: 200,
      // 10% lower than average
      percentageAverageLowerToUpdate: 0.2
    },
    {
      name: 'Tier 4',
      shouldBePurchasedAfterDateFirstActive: false,
      updateDays: 102,
      expiredRewind: 15,
      salesThreshold: 100,
      // 15% lower than average
      percentageAverageLowerToUpdate: 0.2
    },
    {
      name: 'Tier 5',
      shouldBePurchasedAfterDateFirstActive: true,
      updateDays: 128,
      expiredRewind: 15,
      salesThreshold: 50,
      // 15% lower than average
      percentageAverageLowerToUpdate: 0.2
    },
    {
      name: 'Tier 6',
      shouldBePurchasedAfterDateFirstActive: true,
      updateDays: 230,
      expiredRewind: 23,
      salesThreshold: 40,
      // 20% lower than average
      percentageAverageLowerToUpdate: 0.2
    },
    {
      name: 'Tier 7',
      shouldBePurchasedAfterDateFirstActive: true,
      updateDays: 300,
      // 20% lower than average
      percentageAverageLowerToUpdate: 0.2,
      expiredRewind: 23,
      salesThreshold: 25
    },
    {
      name: 'Tier 8',
      shouldBePurchasedAfterDateFirstActive: true,
      updateDays: 320,
      expiredRewind: 23,
      salesThreshold: 0
    }
  ]
};

export const getTierFromTotalSold = (totalSold: number) => {
  const { TIERS } = UPDATE_CONFIG;

  for (let i = 0; i < TIERS.length; i++) {
    if (totalSold >= TIERS[i].salesThreshold) {
      return TIERS[i];
    }
  }

  // return last tier
  return TIERS[TIERS.length - 1];
};

export default UPDATE_CONFIG;
