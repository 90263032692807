import React from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SearchBox from '@components/SearchBox';
import { lightIconStyles } from '../../pageUtils';

const ToolbarSearch = ({
  setSearchOpen
}: {
  setSearchOpen: (value: boolean) => void;
}) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'flex' },
        alignItems: 'center',
        marginLeft: '12px',
        width: '100%'
      }}
    >
      <Box sx={{ flex: '1 1 80%' }}>
        <SearchBox
          callback={() => {
            setSearchOpen(false);
          }}
          autoFocus
          showAll
        />
      </Box>
      <Box sx={{ flex: '0 0 auto', marginLeft: 'auto' }}>
        <IconButton
          onClick={() => {
            setSearchOpen(false);
          }}
          aria-label="Close search"
        >
          <CloseIcon sx={lightIconStyles} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ToolbarSearch;
