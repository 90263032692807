import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { DealPostType } from '@types';
import MonetizingLink from '@components/MonetizingLink';
import { formatPrice, getProductLink } from '@utils/index';
import { getExtraDeal } from '@utils/extraDealMapper/index';

const styles = {
  lineItal: {
    display: 'block',
    fontStyle: 'italic'
  },
  link: {
    fontWeight: 500,
    textDecoration: 'none',
    transition: 'color .5s',
    ':hover': {
      color: '#c9cf6a'
    }
  }
};

interface ExtraDealLineProps {
  deal: DealPostType;
  tag: string;
}

const ExtraDealLine = (props: ExtraDealLineProps) => {
  const { deal, tag } = props;
  const { qtyTerm } = deal;
  const extraDealObj = getExtraDeal(deal);
  const orderCount = extraDealObj.x || extraDealObj.getX;
  const theme = useTheme();

  if (!orderCount) return null; // Early exit if there's no order count

  const extraDealDescription = extraDealObj.extraDeal.toLowerCase();
  const finalPrice = formatPrice(extraDealObj.extraDealPrice);
  const pricePerTerm = extraDealObj.eachTermPrice
    ? ` That's as low as ${formatPrice(extraDealObj.eachTermPrice)}${
        orderCount === 1 ? '' : `/${qtyTerm || 'each'}`
      }!`
    : '';

  const affiliateLink = extraDealObj.extraDealLink
    ? `${extraDealObj.extraDealLink}?tag=${tag}`
    : getProductLink(deal.ASIN, tag);

  return (
    <Typography style={styles.lineItal}>
      *You could also take advantage of the{' '}
      <strong>
        <MonetizingLink
          style={{ ...styles.link, color: theme.palette.linkColor }}
          href={affiliateLink}
          tag={tag}
          clickType="extra-deal-link"
        >
          {extraDealDescription}
        </MonetizingLink>
      </strong>{' '}
      with the final price of {finalPrice} for {orderCount}{' '}
      {orderCount === 1 ? 'order' : `orders`}!*
      {pricePerTerm ? (
        <strong
          style={{
            display: 'block'
          }}
        >
          {pricePerTerm}
        </strong>
      ) : null}
    </Typography>
  );
};

export default ExtraDealLine;
