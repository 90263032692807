import { useQuery, UseQueryResult, useMutation } from 'react-query';
import axios from '@utils/axios';
import { ProductImage } from '@api/database/models/productImage';
import queryClient from '../queryClient';

interface MetaType {
  pictureFile: File | null;
  ASIN: string;
  caption: string;
  type: string;
}

const queryAdminProductImages = async (
  ASIN: string
): Promise<ProductImage[]> => {
  const result = await axios.get<{
    data: ProductImage[];
  }>(`/api/dashboard/product-images/?ASIN=${ASIN}`);
  return result.data;
};

export function useGetAdminProductImages(
  ASIN: string
): UseQueryResult<ProductImage[]> {
  return useQuery<ProductImage[], Error>(
    `admin-product-images-${ASIN}`,
    () => queryAdminProductImages(ASIN),
    {
      onError: () => {
        console.error('Something went wrong while fetching the product images');
      }
    }
  );
}

export function useUploadProductImage() {
  const mutationFn = async ({
    pictureFile,
    caption,
    ASIN,
    type
  }: MetaType): Promise<any> => {
    const formData = new FormData();

    if (pictureFile) {
      formData.append('file', pictureFile, pictureFile.name);
      formData.append('caption', caption);
      formData.append('ASIN', ASIN);
      formData.append('type', type);

      const response = await axios.post<{
        data: ProductImage;
      }>('/api/dashboard/product-images/upload-product-image', formData);
      return response.data;
    }

    throw new Error('No file provided');
  };

  return useMutation(mutationFn, {
    onSuccess: (_data, { ASIN }) => {
      queryClient.refetchQueries(`admin-product-images-${ASIN}`);
      queryClient.refetchQueries(`product-images-${ASIN}`);
    },
    onError: (_data, { ASIN }) => {
      console.error('error uploading product image');
      queryClient.refetchQueries(`admin-product-images-${ASIN}`);
      queryClient.refetchQueries(`product-images-${ASIN}`);
    }
  });
}

export function useDeleteImage() {
  interface RequestAddPromotionData {
    id: number;
    ASIN: string;
  }

  const mutationFn = async ({ id }: RequestAddPromotionData): Promise<null> => {
    return axios.post(`/api/dashboard/product-images/delete`, {
      id
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (_data, { ASIN }) => {
      queryClient.refetchQueries(`product-images-${ASIN}`);
      queryClient.refetchQueries(`admin-product-images-${ASIN}`);
    },
    onError: (_data, { ASIN }) => {
      console.error('error deleting product image');
      queryClient.refetchQueries(`product-images-${ASIN}`);
      queryClient.refetchQueries(`admin-product-images-${ASIN}`);
    }
  });
}

const queryProductImages = async (ASIN: string): Promise<ProductImage[]> => {
  const result = await axios.get<{
    data: ProductImage[];
  }>(`/api/get-product-images/?ASIN=${ASIN}`);
  return result.data;
};

export function useGetProductImages(
  ASIN: string
): UseQueryResult<ProductImage[]> {
  return useQuery<ProductImage[], Error>(
    `product-images-${ASIN}`,
    () => queryProductImages(ASIN),
    {
      onError: () => {
        console.error('Something went wrong while fetching the product images');
      }
    }
  );
}

export function useGetProductImagesNonEnabled(
  ASIN: string
): UseQueryResult<ProductImage[]> {
  return useQuery<ProductImage[], Error>(
    `product-images-${ASIN}`,
    () => queryProductImages(ASIN),
    {
      enabled: false,
      onError: () => {
        console.error('Something went wrong while fetching the product images');
      }
    }
  );
}
