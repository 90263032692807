import React from 'react';
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent
} from '@mui/material';
import postBeginnings from '@utils/dealPostOptions';

const PresetEmailBeginning = ({
  value,
  onChange
}: {
  onChange: (event: SelectChangeEvent<string>) => void;
  value: string;
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="email-beg-select">Preset Email beginning</InputLabel>
      <Select
        labelId="email-beg-select"
        id="email-beg-main-select"
        label="Email beginning"
        value={value}
        onChange={onChange}
      >
        {postBeginnings.map((postBeginning) => (
          <MenuItem key={postBeginning} value={postBeginning}>
            {postBeginning}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PresetEmailBeginning;
