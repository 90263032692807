/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import axios from '@utils/axios';
import config from '@configFile';
import queryClient from '../queryClient';
import { getMailingListById } from '../utils/mailingLists';
import { useGetUserData } from './useGetUserData';
import { useSnackbar } from './useSnackbar';

export function useGetUserMailingLists(): UseQueryResult<string[]> {
  const { data: user } = useGetUserData();
  const queryFn = async (): Promise<string[]> => {
    const result = await axios.get<{ data: string[] }>(
      `/api/get-current-mailing-lists`
    );
    return result.data;
  };

  return useQuery<string[], Error>(
    `user-mailing-list-memberships`,
    () => queryFn(),
    {
      onError: () => {
        console.error(
          'Something went wrong while fetching the user mailing lists'
        );
      },
      enabled: !!user?.firstName
    }
  );
}

export function useToggleMailingListSubscription() {
  const { showMessage } = useSnackbar();
  interface RequestAddData {
    mailingListId: string;
    shouldRemove?: boolean;
  }

  const mutationFn = async ({
    mailingListId,
    shouldRemove
  }: RequestAddData): Promise<null> => {
    return axios.post(`/api/user-toggle-mailing-list`, {
      mailingListId,
      shouldRemove
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (_data, { shouldRemove, mailingListId }) => {
      const mailingList = getMailingListById(mailingListId);
      if (shouldRemove) {
        showMessage(
          `You have been removed from the "${mailingList?.displayName}" mailing list, this will take up to ${config.sendGrid.sendgridJobTime} minutes to take effect.`
        );
      } else {
        showMessage(
          `You have been added to the "${mailingList?.displayName}" mailing list`
        );
      }
      queryClient.refetchQueries('user-mailing-list-memberships');
      queryClient.refetchQueries('pending-mailing-list-removals');
      queryClient.refetchQueries('pending-mailing-list-additions');
    },
    onError: () => {
      showMessage('Something went wrong, please try again later');
    }
  });
}

export const useGetPendingMailingListRemovals = () => {
  const queryFn = async (): Promise<string[]> => {
    const result = await axios.get<{ data: string[] }>(
      `/api/pending-mailing-list-removals`
    );
    return result.data;
  };

  return useQuery<string[], Error>(
    `pending-mailing-list-removals`,
    () => queryFn(),
    {
      onError: () => {
        console.error(
          'Something went wrong while fetching the pending mailing list removals'
        );
      },
      enabled: true
    }
  );
};

export const useGetPendingMailingListAdditions = () => {
  const queryFn = async (): Promise<string[]> => {
    const result = await axios.get<{
      data: string[];
    }>(`/api/pending-mailing-list-additions`);
    return result.data;
  };

  return useQuery<string[], Error>(
    `pending-mailing-list-additions`,
    () => queryFn(),
    {
      onError: () => {
        console.error(
          'Something went wrong while fetching the pending mailing list additions'
        );
      },
      enabled: true
    }
  );
};

export function useEmailSignUp() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    email: string;
    selectedEmails: string[];
    location: string;
  }

  const mutationFn = async ({
    email,
    selectedEmails,
    location
  }: RequestData): Promise<{
    hasAccount: boolean;
    canGetAccount: boolean;
  }> => {
    const response = await axios.post<{
      data: {
        hasAccount: boolean;
        canGetAccount: boolean;
      };
    }>(`/api/sign-up-mailing-lists`, {
      email,
      selectedEmails,
      currentUrl: window?.location?.href,
      location
    });
    return response.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error updating signing up for mailing lists');
    },
    onSuccess: (_data, { selectedEmails }) => {
      const listText = selectedEmails.length > 1 ? 'lists' : 'list';
      showMessage(
        `Successfully signed up for the selected mailing ${listText}!`
      );
    }
  });
}

export function useGlobalUnsubscribeEmail() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    email: string;
  }

  const mutationFn = async ({
    email
  }: RequestData): Promise<{
    success: boolean;
  }> => {
    const response = await axios.post<{ data: { success: boolean } }>(
      `/api/dashboard/globally-remove-email`,
      {
        email
      }
    );
    return response.data;
  };

  return useMutation(mutationFn, {
    onError: (_data, { email }) => {
      console.error(`error globally unsubscribing email ${email}`, _data);
    },
    onSuccess: ({ success }, { email }) => {
      if (success) {
        showMessage(`Successfully globally unsubscribed ${email}!`);
      } else {
        showMessage(`Failed to globally unsubscribe ${email}!`);
      }
    }
  });
}
