import React, { PureComponent } from 'react';
import trackUse from '@utils/trackUse';
import config from '@configFile';

interface Props {
  children?: React.ReactNode;
}

interface State {
  error: Error | null;
  errorInfo: { componentStack: string } | null;
}

export const forceReloadOnError = (error: Error) => {
  console.log('forceReloadOnError triggered with error:', error);

  if (
    /Loading chunk [\d]+ failed/.test(error.message) ||
    /ChunkLoadError/.test(error.message) ||
    /loadable-components/.test(error.message)
  ) {
    console.log('ChunkLoadError detected, forcing reload');
    // Check if window and location objects are available before attempting to reload
    if (typeof window !== 'undefined' && window?.location) {
      // setTimeout to prevent infinite loop
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  } else {
    console.error('Unexpected error occurred:', error);
    const currentpage = window.location.href;
    trackUse({
      item: 'error-boundary',
      value: `${error?.message} - ${currentpage}`,
      type: 'ERROR_BOUNDARY'
    });
  }
};

export default class ErrorBoundary extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    // console.log('ErrorBoundary initialized with state:', this.state);
  }

  static getDerivedStateFromError(error: Error) {
    console.log('Error detected in getDerivedStateFromError:', error);
    // Check for chunk loading error and force reload
    forceReloadOnError(error);

    // Update state so the next render shows the fallback UI
    return { error, errorInfo: null } as any;
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    console.log('componentDidCatch called. Error:', error);
    console.log('componentDidCatch called. ErrorInfo:', errorInfo);

    // Catch errors in any components below and re-render with error message
    this.setState({ error, errorInfo });

    // Additional logging can be added here, e.g., sending logs to a remote server
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { errorInfo, error } = this.state;

    if (errorInfo && config.showErrorOnPage) {
      console.log('Rendering error view due to error:', { error, errorInfo });

      // If there's an error, render error path
      return (
        <div data-testid="error-view">
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error?.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return (
      children || (
        <div>
          Something went wrong. We recommend refreshing the page to continue.
        </div>
      )
    );
  }
}
