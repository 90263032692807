import React, { memo } from 'react';
import {
  Typography,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton
} from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import trackUse from '@utils/trackUse';
import {
  getProductLink,
  getPostPrice,
  parseTitle,
  logPostHogEvent
} from '@utils/index';
import { iconButtonHoverRaiseStyle } from '@utils/styles';
import { DealPostType } from '@types';
import MonetizingLink from '@components/MonetizingLink';
import AdminActions from '@components/AdminActions';
import { dealsAction } from '../../../../../../actions';

const CouponListItem = ({
  latestDeal,
  tag
}: {
  latestDeal: DealPostType;
  tag: string;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <MonetizingLink
      key={latestDeal.ASIN}
      href={getProductLink(latestDeal.ASIN, tag)}
      target="_blank"
      underline="none"
      tag={tag}
      item="amazon-coupon-active-link"
      clickType={getProductLink(latestDeal.ASIN, tag)}
    >
      <ListItem
        sx={{
          display: 'flex',
          '@media (max-width: 540px)': {
            paddingLeft: '0px'
          }
        }}
        secondaryAction={
          <>
            <IconButton
              edge="end"
              aria-label="see details"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                trackUse({
                  item: 'amazon-coupon-details-link',
                  value: latestDeal.ASIN,
                  type: 'ACTION'
                });

                logPostHogEvent('amazon-coupon-details-link', {
                  value: latestDeal.ASIN,
                  type: 'ACTION'
                });

                // window.location.href = `/deal/${latestDeal.ASIN}/`;
                history.push(`/deal/${latestDeal.ASIN}/`);
                dispatch(dealsAction.getDealSummary(latestDeal.ASIN));
                e.preventDefault();
              }}
              sx={iconButtonHoverRaiseStyle}
            >
              <ReceiptLongIcon />
            </IconButton>
            <AdminActions ASIN={latestDeal.ASIN} deal={latestDeal} />
          </>
        }
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              width: '80px',
              height: '80px',
              objectFit: 'contain',
              '@media (max-width: 540px)': {
                width: '60px',
                height: '60px'
              }
            }}
            src={
              latestDeal.image100Url?.replace('.jpg', '.webp') ||
              latestDeal.image240Url?.replace('.jpg', '.webp') ||
              latestDeal.image600Url?.replace('.jpg', '.webp') ||
              latestDeal.image
            }
            variant="square"
          />
        </ListItemAvatar>
        <ListItemText
          sx={{
            marginLeft: '12px',
            '@media (max-width: 540px)': {
              fontSize: '13px',
              paddingLeft: '0px'
            }
          }}
          primary={
            <Typography
              variant="body1"
              sx={{
                '@media (max-width: 540px)': {
                  fontSize: '13px'
                }
              }}
            >
              {parseTitle(latestDeal.title)}
            </Typography>
          }
          secondary={getPostPrice(latestDeal)}
        />
      </ListItem>
    </MonetizingLink>
  );
};

export default memo(CouponListItem);
