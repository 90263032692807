/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import axios from '@utils/axios';
import queryClient from '../queryClient';
import { SSType } from '../types';
import { useSnackbar } from './useSnackbar';

interface DashboardData {
  metricData: Array<{
    name: string;
    data: number;
  }>;
  newDeals: Array<SSType>;
}

const queryDashboard = async (): Promise<DashboardData> => {
  const result = await axios.get<{ data: DashboardData }>(
    `/api/dashboard/getDashboard`
  );
  return result.data;
};

export function useGetDashboard(): UseQueryResult<DashboardData> {
  return useQuery<DashboardData, Error>(
    `admin-dashboard`,
    () => queryDashboard(),
    {
      onError: () => {
        console.error('Something went wrong while fetching the dashboard');
      }
    }
  );
}

export function useAddDeal() {
  const { showMessage } = useSnackbar();
  interface RequestAddDeal {
    ASINs: Array<{
      ASIN: string;
      price: number;
    }>;
  }

  const mutationFn = async ({
    ASINs
  }: RequestAddDeal): Promise<{
    response: string;
  }> => {
    const response = await axios.post<{ data: { response: string } }>(
      `/api/dashboard/bulkAddASINCouponDeal`,
      {
        ASINs
      }
    );

    return response.data;
  };

  return useMutation(mutationFn, {
    onSuccess: (res) => {
      queryClient.refetchQueries(`admin-dashboard`);
      showMessage(res?.response);
    },
    onError: () => {
      console.error('error adding deal');
    }
  });
}
