import config from '@configFile';
import { OptionType, DealsIdeasType, OCRImageData, DealPostType } from '@types';

import {
  daysUntil,
  daysRemaining,
  formatDateTime,
  formattedDate,
  isDateWithinLast24Hours,
  formatDateUpdatedToday,
  isPrimeDay,
  isBeforePrimeDay,
  isBeforeLaborDay,
  isLaborDay,
  isCyberMonday,
  displayShippingCutoffMessage,
  isBeforeCyberMonday,
  getDaysBetweenNowAndDate,
  displayTwelveDaysOfDeals,
  isBeforeBlackFriday,
  isBlackFriday,
  isBlackFridayDealsWeek
} from './dateUtils';

import {
  getCurrentDealEventStrObj,
  getFutureEventListingMode,
  getCurrentEventListingMode,
  hasFutureOrCurrentEvent
} from './eventUtils';

import {
  shareUrl,
  copyTextToClipboard,
  isShareAPISupported,
  stopPropagationForTab,
  exportCSV
} from './browserUtils';

import {
  sortDeals,
  getOverAllPercentageDiscount,
  getPercentageDiscountFromListPrice,
  getEarliestLatestDealsDateActive,
  findHighestPercentageDiscount,
  findHighestPercentageDiscountASIN
} from './sortUtils';
import { showNewIfNew } from './emailFormatUtils';

import { truncateTitle, parseSize, parseTitle } from './titleUtils';

import {
  addAffiliateTagToLink,
  sanitizeFeature,
  getBrandsString,
  getPostPrice,
  lowercaseFirstLetter,
  getQtyString,
  shouldShowFinalPrice,
  shouldShowListPrice,
  renderListPrice,
  formatPrice,
  formatNumberToThousands,
  isValidEmail
  // getListPrice
} from './formatUtils';
import {
  getHowManyDealsByPromoCode,
  getExtraDeals,
  getHowManyDealsByBrand,
  getHowManyExtraDealsByExtraDealLink,
  getBrands,
  getCoupons,
  getBrandStringFromBrands
} from './organizeUtils';
import {
  getPageDealBlogUrlFromSlug,
  getPageDealUrlFromSlug,
  getTitleFromPageDeal,
  getDescriptionFromPageDeal,
  getPageDealUrlPartFromSlug
} from './pageDealUtils';

import { getExtraDealPrice, getPriceLineCalculations } from './priceUtils';

import {
  isPasswordOkay,
  isNameProhibited,
  removeNonAlphanumeric
} from './securityUtils';

import {
  getProductLink,
  getNonAffiliateLink,
  getCouponLink,
  getCouponLinkFromCode,
  getAffiliateTagFromLink
} from './linkUtils';

import {
  trackLinkClick,
  logPostHogEvent,
  logPostHogClick
} from './trackingUtils';

export const categoryAll = {
  label: 'Show all categories',
  value: 'none'
};

export const defaultCategories = [categoryAll];

export {
  findHighestPercentageDiscount,
  findHighestPercentageDiscountASIN,
  getEarliestLatestDealsDateActive,
  trackLinkClick,
  logPostHogEvent,
  logPostHogClick,
  getCouponLinkFromCode,
  getProductLink,
  getNonAffiliateLink,
  getCouponLink,
  isPasswordOkay,
  isNameProhibited,
  removeNonAlphanumeric,
  getDescriptionFromPageDeal,
  getTitleFromPageDeal,
  getPageDealBlogUrlFromSlug,
  getPageDealUrlFromSlug,
  daysUntil,
  parseTitle,
  getExtraDeals,
  parseSize,
  daysRemaining,
  formatDateTime,
  formattedDate,
  getCurrentDealEventStrObj,
  getAffiliateTagFromLink,
  addAffiliateTagToLink,
  isDateWithinLast24Hours,
  formatDateUpdatedToday,
  shareUrl,
  copyTextToClipboard,
  hasFutureOrCurrentEvent,
  isShareAPISupported,
  stopPropagationForTab,
  exportCSV,
  sortDeals,
  getPercentageDiscountFromListPrice,
  getOverAllPercentageDiscount,
  showNewIfNew,
  getPostPrice,
  lowercaseFirstLetter,
  formatPrice,
  formatNumberToThousands,
  shouldShowListPrice,
  getQtyString,
  getFutureEventListingMode,
  getCurrentEventListingMode,
  shouldShowFinalPrice,
  isValidEmail,
  getBrandsString,
  renderListPrice,
  truncateTitle,
  sanitizeFeature,
  // getListPrice,
  getExtraDealPrice,
  getHowManyDealsByBrand,
  getHowManyDealsByPromoCode,
  getHowManyExtraDealsByExtraDealLink,
  getBrands,
  getCoupons,
  getBrandStringFromBrands,
  getPriceLineCalculations,
  isPrimeDay,
  isLaborDay,
  isCyberMonday,
  displayShippingCutoffMessage,
  isBeforeCyberMonday,
  getDaysBetweenNowAndDate,
  displayTwelveDaysOfDeals,
  isBeforeBlackFriday,
  isBlackFriday,
  isBlackFridayDealsWeek,
  isBeforeLaborDay,
  isBeforePrimeDay,
  getPageDealUrlPartFromSlug
};

export type CouponDiscountType = Pick<
  DealPostType,
  'couponPercentage' | 'couponFixed' | 'promoCode'
>;

export const ensureWebP = (url: string): string => {
  if (url.includes('.webp')) {
    return url;
  }
  return `${url}`.replace('.jpg', '.webp').replace('.png', '.webp');
};

export const makeUrlSlugFromProductTitle = (title: string) => {
  // Only work with the first 90 characters initially
  let newTitle = title.substring(0, 90);

  // Find the last space within the first 90 characters to avoid cutting off a word
  const lastSpaceIndex = newTitle.lastIndexOf(' ');

  if (lastSpaceIndex !== -1) {
    // Trim the title to the last complete word if a space exists within the substring
    newTitle = newTitle.substring(0, lastSpaceIndex);
  }

  // Convert to slug format
  return (
    newTitle
      ?.toLowerCase()
      ?.replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with dashes
      ?.replace(/^-|-$/g, '') || '' // Remove leading and trailing dashes
  );
};

export const removeParagraphIndentation = (text: string): string => {
  // Use a regular expression to match and remove leading spaces in paragraphs
  return text.replace(/\n\s+/g, '\n\n').replace('Hey there! ', '');
};

export const isSingleCategoryAll = (category: OptionType) => {
  return (
    category.value === categoryAll.value && category.label === categoryAll.label
  );
};

function customRound(value: number): number {
  // If the decimal part is less than 0.5, round down; if 0.5 or greater, round up
  const decimalPart = value % 1;

  if (decimalPart < 0.5) {
    return Math.floor(value);
  }
  return Math.ceil(value);
}

export const priceToTwoDecimalPlaces = (num: number): number => {
  // if number is 0.005 then it should be 0.01
  // return Math.round(num * 100) / 100;
  return customRound(num * 100) / 100;
};

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

//
export const getPostPrefix = () => {
  let preFix = ``;
  const dealEvent = getCurrentDealEventStrObj();

  if (dealEvent?.showSitePrefix) {
    preFix = `${
      dealEvent?.emailPrefix || dealEvent?.prefix || config.SITE_PREFIX
    }: `;
  }

  return preFix;
};

export const addPrefixToStringIfNotAlready = (str: string) => {
  const prefix = getPostPrefix();
  if (str.startsWith(prefix)) {
    return str;
  }
  return `${prefix}${str}`;
};

export const isImageBanned = (image: string, ocrImages: OCRImageData[]) => {
  const bannedImage = ocrImages?.find((img) => img.image === image);
  return !!bannedImage?.isBanned;
};

export const scrollToHash = (hash: string) => {
  const element = document.querySelector(hash);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

interface ParamsObj {
  [key: string]: string;
}

const removeAfterSecondQuestionMark = (url: string): string => {
  return url.replace(/\?(.*?)\?.*/, '?$1');
};

export const getParamsFromURLSearch = (url: string): ParamsObj => {
  const search = removeAfterSecondQuestionMark(url || '').substring(1);
  if (!search) {
    return {};
  }

  let obj = {};

  try {
    obj = JSON.parse(
      `{"${decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  } catch (e) {
    console.error('Error parsing URL search', e);
  }

  return obj;
};

export function getTabNumberFromUrlSearch(url: string): number {
  const params = getParamsFromURLSearch(url) as unknown as { tab?: string };
  return params.tab ? parseInt(params.tab, 10) : 0;
}

export const roundTo = (numToRound: number, digitsToRound: number) => {
  let n = numToRound;
  let digits = digitsToRound;

  if (digits === undefined) {
    digits = 0;
  }

  const multiplicator = 10 ** digits;
  n = parseFloat((n * multiplicator).toFixed(11));
  return (Math.round(n) / multiplicator).toFixed(digits);
};

export const getCouponOffMessage = (
  couponFixed: number | null,
  couponPercentage: number | null
): string => {
  if (!couponFixed && !couponPercentage) {
    return '';
  }

  return couponFixed
    ? `${formatPrice(couponFixed)} off`
    : `${couponPercentage}% off`;
};

type AmountType = Pick<DealsIdeasType, 'listPrice' | 'finalPrice'>;

export const getPercentageDiscountDealsIdea = (deal: AmountType) => {
  if (
    !deal.listPrice ||
    !deal.finalPrice ||
    deal.finalPrice < 0 ||
    deal.listPrice < 0
  ) {
    return 0;
  }

  return (
    (1 - parseFloat(`${deal.finalPrice}`) / parseFloat(`${deal.listPrice}`)) *
    100
  );
};

export const getDealsString = (len: number) => {
  return len === 1 ? 'deal' : 'deals';
};

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

export const getCouponString = ({
  couponPercentage,
  couponFixed
}: CouponDiscountType) => {
  if (couponPercentage === undefined && couponFixed === undefined) {
    return null;
  }

  if (
    couponPercentage !== null &&
    couponPercentage !== 0 &&
    couponPercentage !== undefined
  ) {
    return `${couponPercentage}%`;
  }

  if (!couponFixed) {
    return null;
  }

  if (typeof couponFixed === 'string') {
    return `$${couponFixed}`;
  }

  return `${formatPrice(couponFixed)}`;
};

export const hashASIN = (asin: string) => {
  let hash = 0;
  for (let i = 0; i < asin.length; i++) {
    const char = asin.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
};
