import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
  Divider,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Box
} from '@mui/material';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { useGetUserData } from '@hooks/useGetUserData';
import { stopPropagationForTab } from '@utils/index';
import { AdminButtonProps } from '@components/AdminActions/utils';
import {
  useGetAdminProductImages,
  useUploadProductImage,
  useDeleteImage
} from '@hooks/useProductImages';
import SpinningIcon from '@components/SpinningIcon';

const productImageLabelTypes = [
  {
    label: 'Haul',
    value: 'HAUL'
  },
  {
    label: 'Compare image',
    value: 'COMPARE_TO'
  }
];

const ProductImagesContent = ({ ASIN }: { ASIN: string }) => {
  const { data: productImages, refetch } = useGetAdminProductImages(ASIN);
  const { mutate: deleteImage, isLoading: isDeleting } = useDeleteImage();
  const [file, setFile] = useState<File | null>(null);
  const [caption, setCaption] = useState<string>('');
  const [type, setType] = useState<string>('HAUL');
  const uploadImage = useUploadProductImage();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (file) {
      uploadImage.mutate(
        { pictureFile: file, ASIN, caption, type },
        {
          onSuccess: () => {
            refetch();
            setFile(null);
            setCaption('');
          }
        }
      );
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const renderProductImageUpload = () => {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <Box marginBottom="8px">
            <TextField
              type="file"
              onChange={handleFileChange}
              variant="outlined"
            />
          </Box>
          <Box marginBottom="8px">
            <TextField
              label="Caption"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              fullWidth
            />
          </Box>
          <Box marginBottom="8px">
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {productImageLabelTypes.map((labelType) => (
                  <MenuItem key={labelType.value} value={labelType.value}>
                    {labelType.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={uploadImage.isLoading || !file || !caption}
              startIcon={
                uploadImage.isLoading ? <SpinningIcon isLoading /> : null
              }
            >
              Add product image
            </Button>
          </Box>
          {uploadImage.isError && <p>Error uploading image</p>}
        </form>
      </>
    );
  };

  return (
    <>
      {renderProductImageUpload()}
      <Divider
        style={{
          marginBottom: 12,
          marginTop: 8
        }}
      />
      {productImages?.length > 0 ? (
        <>
          {productImages?.map((productImage) => (
            <Box
              key={productImage.id}
              style={{
                marginBottom: 12
              }}
            >
              <img
                key={productImage.id}
                src={productImage.image}
                alt={productImage.caption}
                style={{
                  maxWidth: '100%'
                }}
              />
              <Box mb={1} mt={1}>
                <Typography variant="caption" gutterBottom>
                  {productImage.caption}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                mb={1}
              >
                <TextField
                  label="Blog code"
                  value={`<ProductImage ASIN="${ASIN}" image="${productImage.image}" />`}
                  onChange={() => {}}
                  fullWidth
                />
              </Box>
              <Button
                disabled={isDeleting}
                onClick={() => {
                  deleteImage({ id: productImage.id, ASIN });
                }}
                startIcon={isDeleting ? <SpinningIcon isLoading /> : null}
              >
                Remove product image
              </Button>
            </Box>
          ))}
        </>
      ) : (
        <Typography variant="body1" gutterBottom>
          No product images
        </Typography>
      )}
    </>
  );
};

const ProductImages = ({
  ASIN,
  onCloseCallback = () => {}
}: AdminButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: user } = useGetUserData();

  const handleModalClose = () => {
    setModalOpen(false);
    onCloseCallback();
  };

  if (!user?.isAdmin || !ASIN) {
    return null;
  }

  const renderProductImagesModal = () => {
    if (modalOpen) {
      return (
        <Dialog
          open
          onClose={handleModalClose}
          fullWidth
          onKeyDown={stopPropagationForTab}
        >
          <DialogTitle>Add Product Images</DialogTitle>
          <DialogContent>
            <ProductImagesContent ASIN={ASIN} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Close</Button>
          </DialogActions>
        </Dialog>
      );
    }

    return null;
  };

  return (
    <>
      {modalOpen ? renderProductImagesModal() : null}
      <MenuItem
        key="admin-product-images-modal"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <ListItemIcon>
          <AddToPhotosIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Product Images</ListItemText>
      </MenuItem>
    </>
  );
};

export default ProductImages;
