export const customCyberMondayLoadingMessages = [
  'Scoring the hottest Cyber Monday Amazon deals for you...',
  'Surfing the web for unbeatable Cyber Monday savings...',
  `Fetching today's Cyber Monday steals...`,
  'Hunting down exclusive Cyber Monday discounts...',
  'Loading the ultimate Cyber Monday offers...',
  'Sorting through endless Cyber Monday deals to bring you the best...',
  'Unpacking epic Cyber Monday savings just for you...',
  `Clicking through Amazon for Cyber Monday's top deals...`,
  'Polishing up your perfect Cyber Monday bargain...',
  'Finalizing the best Cyber Monday steals on the web...'
];

export const customBlackFridayLoadingMessages = [
  'Scoring the hottest Black Friday Amazon deals for you...',
  'Rushing through the aisles of Black Friday savings...',
  `Fetching today's Black Friday steals...`,
  'Hunting down exclusive Black Friday discounts...',
  'Loading the ultimate Black Friday offers...',
  'Sorting through endless Black Friday deals to bring you the best...',
  'Unpacking jaw-dropping Black Friday savings just for you...',
  `Stampeding through Amazon for Black Friday's top deals...`,
  'Polishing up your perfect Black Friday bargain...',
  'Finalizing the best Black Friday steals on the web...'
];

export const holidayLoadingMessages = [
  'Scoring the best holiday deals for you...',
  'Unwrapping festive savings just for you...',
  `Fetching today's holly jolly bargains...`,
  'Hunting down exclusive holiday discounts...',
  'Loading up your sleigh with amazing holiday offers...',
  'Sorting through holiday deals to bring you the best...',
  'Decking the halls with epic savings...',
  'Spreading cheer with incredible holiday bargains...',
  'Polishing up your perfect holiday gifts...',
  'Finalizing the best holiday deals for your list...'
];

export const lastMinuteLoadingMessages = [
  'Racing to find the best last-minute holiday deals for you...',
  'Unwrapping last-minute savings just in time...',
  `Fetching holiday bargains before it's too late...`,
  'Hunting down exclusive last-minute discounts...',
  'Loading up your sleigh with deals at the last second...',
  'Sorting through holiday deals to save the day...',
  'Decking the halls with last-minute savings...',
  'Spreading cheer with lightning-fast holiday bargains...',
  'Polishing up your perfect last-minute holiday gifts...',
  'Finalizing incredible deals before the holidays arrive...'
];
