import React, { useState } from 'react';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Avatar,
  DialogActions
} from '@mui/material';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';
import ShowIcon from '@components/ShowIcon';
import { FilterChipConfigType } from '@types';

const CategoryChip = ({
  categoryFilters,
  setCategoryFilters,
  categoryOptions
}: {
  categoryFilters: string[];
  setCategoryFilters: (value: string[]) => void;
  categoryOptions: FilterChipConfigType;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleChipClick = (filter: any) => {
    logPostHogEvent('filter-chip', {
      value: `${filter.label} - ${filter.selected ? 'DESELECT' : 'SELECT'}`,
      type: 'ACTION'
    });
    trackUse({
      item: `filter-chip`,
      value: `${filter.label} - ${filter.selected ? 'DESELECT' : 'SELECT'}`,
      type: 'ACTION'
    });

    // Call the provided onClick handler
    filter.onClick();
  };

  const renderModal = () => {
    return (
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 535 } }}
        maxWidth="xs"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="filter-dialog-title"
      >
        <DialogTitle id="filter-dialog-title">Filter by Category</DialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1 // Add spacing between chips
            }}
          >
            {categoryOptions.map((filter) => (
              <Chip
                key={filter.label}
                label={filter.label}
                onClick={() => handleChipClick(filter)}
                sx={{
                  cursor: 'pointer'
                }}
                icon={
                  <ShowIcon
                    icon={filter.icon}
                    // use style for not mui libraries
                    style={{ color: filter.iconColor, fontSize: '24px' }}
                  />
                }
                variant={filter.selected ? 'filled' : 'outlined'}
                aria-pressed={filter.selected} // Accessibility for toggle buttons
                onDelete={
                  filter.selected ? () => handleChipClick(filter) : undefined
                }
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'space-between',
            display: 'flex'
          }}
        >
          <Button
            onClick={() => {
              setCategoryFilters([]);
              setModalOpen(false);
            }}
          >
            Clear All
          </Button>
          <Button
            autoFocus
            onClick={() => setModalOpen(false)}
            color="primary"
            variant="outlined"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {renderModal()}
      <Chip
        label={
          categoryFilters?.length === 1 || categoryFilters?.length === 0
            ? 'Category'
            : 'Categories'
        }
        onClick={() => {
          setModalOpen(true);
        }}
        sx={{
          marginRight: '6px'
        }}
        variant={categoryFilters?.length > 0 ? 'filled' : 'outlined'}
        avatar={
          categoryFilters.length === 0 ? undefined : (
            <Avatar>{categoryFilters.length}</Avatar>
          )
        }
        onDelete={
          categoryFilters?.length === 0
            ? undefined
            : () => {
                setCategoryFilters([]);
              }
        }
        aria-label="Open category filter dialog"
      />
    </>
  );
};

export default CategoryChip;
