/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';

import ShowIcon from '@components/ShowIcon';
import { FilterChipConfigItem } from '@types';

const MainFilterChip = ({ filter }: { filter: FilterChipConfigItem }) => {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(!!filter?.selected);
  }, [filter.selected]);

  const color = selected ? 'primary' : 'default';
  const selectedChipStyles = {
    backgroundColor: '#392e50 !important',
    color: 'white !important'
  };

  const selectedColor = filter?.selectedColor || filter?.iconColor || '#616161';
  const iconColor = selected ? selectedColor : filter?.iconColor || '#616161';

  return (
    <Chip
      color={color}
      key={filter.label}
      label={filter.label}
      variant="filled"
      icon={
        <ShowIcon
          sx={{
            color: iconColor
          }}
          // @ts-ignore
          color={iconColor}
          icon={filter.icon}
        />
      }
      onClick={() => {
        trackUse({
          item: `filter-chip-category`,
          value: `${filter.label} - ${filter.selected ? 'DESELECT' : 'SELECT'}`,
          type: 'ACTION'
        });
        logPostHogEvent('filter-chip-category', {
          value: `${filter.label} - ${filter.selected ? 'DESELECT' : 'SELECT'}`,
          type: 'ACTION'
        });
        filter.onClick();
      }}
      sx={{
        marginRight: '6px',
        ...(selected ? selectedChipStyles : {})
      }}
      onDelete={selected ? filter.onClick : undefined}
    />
  );
};
export default MainFilterChip;
