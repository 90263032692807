/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation } from 'react-query';
import axios from '@utils/axios';

export function useSendFeedback() {
  interface RequestAddData {
    feedback: string;
    feedbackType: string;
  }

  const mutationFn = async ({
    feedback,
    feedbackType
  }: RequestAddData): Promise<null> => {
    return axios.post(`/api/send-feedback`, {
      feedback,
      feedbackType
    });
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error sending feedback');
    }
  });
}
