// deal-logs/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult } from 'react-query';
import axios from '@utils/axios';
import { DealLog } from '../types';

export function useGetDealLogs(ASIN: string): UseQueryResult<DealLog[]> {
  const queryFn = async (ASIN: string): Promise<DealLog[]> => {
    const result = await axios.get<{
      data: DealLog[];
    }>(`/api/dashboard/deal-logs/get?ASIN=${ASIN}`);
    return result.data;
  };

  return useQuery<DealLog[], Error>(`dealLogs-${ASIN}`, () => queryFn(ASIN), {
    onError: () => {
      console.error('Something went wrong while fetching the deal logs');
    }
  });
}
