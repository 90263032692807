/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import { loadableReady } from '@loadable/component';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { StyledEngineProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { QueryClientProvider } from 'react-query';
import config from '@configFile';
import createEmotionCache from './createEmotionCache';
import { SnackbarProvider } from './hooks/useSnackbar';
import { LoginModalProvider } from './hooks/useLoginModal';
import queryClient from './queryClient';
import ThemeContextProvider from './ThemeContextProvider';

import configureStore from './utils/configureStore';
import routes from './routes';

// Create the emotion cache
const cache = createEmotionCache();

// Get the initial state from server-side rendering
const initialState = window.__INITIAL_STATE__;
const { store, history } = configureStore({ initialState });

const render = (Routes: Array<object>) => {
  const renderMethod = (module as any).hot ? ReactDOM.render : ReactDOM.hydrate;

  renderMethod(
      <GoogleOAuthProvider clientId="135329434554-k4db2qolei4ej44835kv5hq05tupvaij.apps.googleusercontent.com">
        <SnackbarProvider>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <QueryClientProvider client={queryClient}>
                <CacheProvider value={cache}>
                    <StyledEngineProvider injectFirst>
                      <ThemeContextProvider>
                        <LoginModalProvider>
                          {renderRoutes(Routes)}
                        </LoginModalProvider>
                      </ThemeContextProvider>
                    </StyledEngineProvider>
                </CacheProvider>
              </QueryClientProvider>
            </ConnectedRouter>
          </Provider>
        </SnackbarProvider>
      </GoogleOAuthProvider>,
    document.getElementById('react-view')
  );
};

// loadable-component setup
loadableReady(() => {
  render(routes);
});

if ((module as any).hot) {
  // Enable webpack hot module replacement for routes
  (module as any).hot.accept('./routes', () => {
    try {
      const nextRoutes = require('./routes').default;

      render(nextRoutes);
    } catch (error) {
      console.error(`==> 😭  Routes hot reloading error ${error}`);
    }
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
function register() {
  if ('serviceWorker' in navigator && config.USE_SERVICE_WORKER) {
    // @ts-ignore
    window.self.__WB_DISABLE_DEV_LOGS = false; // Enable Workbox logs
    
    navigator.serviceWorker
      .register(`/assets/sw.js?v=${new Date().getTime()}`, { scope: '/assets/' })
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);

        // Check for updates
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;

          if (!installingWorker) return;

          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log('New content is available.');

                // Notify the user about the update
                showUpdateNotification(() => {
                  registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
                  window.location.reload();
                });
              } else {
                console.log('Content is cached for offline use.');
              }
            }
          };
        };
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }
}

function showUpdateNotification(onConfirm: () => void) {
  const toast = document.createElement('div');
  toast.style.position = 'fixed';
  toast.style.bottom = '20px';
  toast.style.left = '50%';
  toast.style.transform = 'translateX(-50%)';
  toast.style.backgroundColor = '#333';
  toast.style.color = '#fff';
  toast.style.padding = '10px 20px';
  toast.style.borderRadius = '5px';
  toast.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.2)';
  toast.style.zIndex = '1000';
  toast.innerHTML = 'A new version is available. Click to refresh.';

  toast.addEventListener('click', () => {
    onConfirm();
    document.body.removeChild(toast);
  });

  document.body.appendChild(toast);
}

register();

// export function unregister() {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.ready
//       .then((registration) => registration.unregister())
//       .catch((err) => console.error(err.message));
//   }
// }