import React, { useState, useMemo } from 'react';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Avatar,
  DialogActions,
  Skeleton
} from '@mui/material';
import times from 'lodash/times';
import { useTheme } from '@mui/system';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';
import { useGetBrandFilters } from '@hooks/useDeals';
import { DealPostType } from '@types';

type BrandChipProps = {
  setBrandFilters: (value: string[]) => void;
  brandFilters: string[];
  currentlyShowingDeals: DealPostType[];
  latestDealsLoading: boolean;
};

const BrandChip: React.FC<BrandChipProps> = ({
  setBrandFilters,
  brandFilters,
  currentlyShowingDeals,
  latestDealsLoading
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const { data: brands, isLoading: isLoadingBrands } = useGetBrandFilters();
  const loading = latestDealsLoading || isLoadingBrands;

  const mapOfBrandsWithCount = useMemo(() => {
    const map = new Map<string, number>();
    currentlyShowingDeals.forEach((deal) => {
      if (deal.brand) {
        map.set(deal.brand, (map.get(deal.brand) || 0) + 1);
      }
    });
    return map;
  }, [currentlyShowingDeals]);

  const getCountFromBrand = (brand: string) => {
    return mapOfBrandsWithCount.get(brand) || 0;
  };

  const displayBrands = useMemo(() => {
    // sort by getCountFromBrand
    return (brands || [])
      .filter((brand) => getCountFromBrand(brand) > 1)
      .sort((a, b) => {
        return getCountFromBrand(b) - getCountFromBrand(a);
      });
  }, [brands, mapOfBrandsWithCount]);

  const handleChipClick = (brand: string) => {
    const deselected = !!brandFilters?.includes(brand);
    logPostHogEvent(`brand-chip`, {
      value: `${brand}-${deselected ? 'deselected' : 'selected'}`,
      type: 'ACTION'
    });
    trackUse({
      item: `brand-chip`,
      value: `${brand}-${deselected ? 'deselected' : 'selected'}`,
      type: 'ACTION'
    });

    if (brandFilters.includes(brand)) {
      setBrandFilters(brandFilters.filter((filter) => filter !== brand));
    } else {
      setBrandFilters([...brandFilters, brand]);
    }
  };

  const renderModal = () => (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 535 } }}
      maxWidth="xs"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="brand-dialog-title"
    >
      <DialogTitle id="brand-dialog-title">Filter by Brand</DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1
          }}
        >
          {loading
            ? // Show skeleton loaders if loading
              times(8).map((idx) => (
                <Skeleton
                  key={idx}
                  variant="rectangular"
                  width="80px"
                  height="32px"
                  sx={{ borderRadius: '16px' }}
                />
              ))
            : displayBrands.map((brand) => (
                <Chip
                  key={brand}
                  label={brand}
                  avatar={
                    <Avatar
                      sx={{
                        color: isDarkMode ? '#FFFFFF' : '#141414',
                        bgcolor: isDarkMode ? '#333' : '#EEE'
                      }}
                    >
                      {getCountFromBrand(brand)}
                    </Avatar>
                  }
                  onClick={() => handleChipClick(brand)}
                  variant={brandFilters.includes(brand) ? 'filled' : 'outlined'}
                  onDelete={
                    brandFilters.includes(brand)
                      ? () => handleChipClick(brand)
                      : undefined
                  }
                />
              ))}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          display: 'flex'
        }}
      >
        <Button
          onClick={() => {
            setBrandFilters([]);
            setModalOpen(false);
          }}
        >
          Clear All
        </Button>
        <Button
          autoFocus
          onClick={() => setModalOpen(false)}
          color="primary"
          variant="outlined"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {renderModal()}
      <Chip
        label={
          brandFilters.length === 1 || brandFilters.length === 0
            ? 'Brand'
            : 'Brands'
        }
        onClick={() => setModalOpen(true)}
        variant={brandFilters.length > 0 ? 'filled' : 'outlined'}
        avatar={
          brandFilters.length > 0 ? (
            <Avatar>{brandFilters.length}</Avatar>
          ) : undefined
        }
        onDelete={
          brandFilters.length === 0
            ? undefined
            : () => {
                setBrandFilters([]);
              }
        }
        aria-label="Open brand filter dialog"
        sx={{ marginRight: '6px' }}
      />
    </>
  );
};

export default BrandChip;
