import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import colors from '@utils/colors';
import { isCurrentlyOnPage } from '../../app/utils';

interface MenuButtonProps {
  linkList: Array<{
    name: string;
    link?: string;
    onClick?: () => void;
    icon: React.ReactNode;
  }>;
  pathname: string;
  linkHeader: string;
  onClick: (page: {
    name: string;
    link?: string;
    onClick?: () => void;
  }) => void;
}

const MenuButton = ({
  linkList,
  linkHeader,
  pathname,
  onClick
}: MenuButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClick = (indexClicked: number) => {
    onClick(linkList[indexClicked]);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setOpen(false);
    handleClick(index);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = (event: React.MouseEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.relatedTarget as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box
      display="inline"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ButtonGroup
        disableElevation
        variant="contained"
        ref={anchorRef}
        aria-label="Top Lists"
      >
        <Button
          onClick={handleToggle}
          sx={{
            backgroundColor: colors.jdbPurple
          }}
        >
          {linkHeader}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 equal columns
                    gap: 1 // Space between items
                  }}
                >
                  {linkList.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      selected={isCurrentlyOnPage(option.link, pathname)}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <ListItemIcon>{option.icon}</ListItemIcon>
                      <ListItemText>{option.name}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default MenuButton;
