import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import MonetizingLink from '@components/MonetizingLink';
import {
  getCouponLinkFromCode,
  CouponDiscountType,
  getCouponString
} from '@utils/index';

export default ({
  couponPercentage,
  couponFixed,
  promoCode,
  tag
}: CouponDiscountType & {
  tag: string;
}) => {
  const theme = useTheme();
  const couponString = getCouponString({ couponPercentage, couponFixed });

  if (!couponString) {
    return null;
  }

  if (promoCode) {
    return (
      <Typography variant="body2">
        {'Click to instantly '}
        <MonetizingLink
          href={getCouponLinkFromCode(promoCode, tag)}
          tag={tag}
          clickType="deal-post-card-coupon-discount"
          target="_blank"
          sx={{
            color: theme.palette.linkColor,
            fontWeight: 400
          }}
          underline="hover"
        >
          {`clip the `}
          <strong>{couponString}</strong>
          {` off coupon`}
        </MonetizingLink>
      </Typography>
    );
  }

  return (
    <Typography variant="body2">
      {'Click to instantly '}
      <b>
        {`clip the `}
        <strong>{couponString}</strong>
        {` off coupon`}
      </b>
      {' on the product page '}
    </Typography>
  );
};
