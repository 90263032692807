import React from 'react';
import { Link } from '@mui/material';
import { useTheme } from '@mui/system';

const SubscribeSaveLink = () => {
  const theme = useTheme();
  return (
    <Link
      href="/best-amazon-subscribe-save-deals"
      underline="none"
      sx={{
        color: theme.palette.linkColor,
        fontWeight: 400
      }}
      aria-label="Read more about Subscribe & Save"
    >
      Subscribe & Save
    </Link>
  );
};

export default SubscribeSaveLink;
