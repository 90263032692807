/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation } from 'react-query';
import axios from '@utils/axios';

export interface RecaptchaResponse {
  success: boolean;
  challenge_ts: string;
  hostname: string;
}

export function useVerifyRecapture() {
  const mutationFn = async (
    captchaValue: string
  ): Promise<RecaptchaResponse> => {
    const result = await axios.post<{
      data: RecaptchaResponse;
    }>(`/api/verify-not-robot`, {
      captchaValue
    });
    return result.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('Error sending');
    }
  });
}
