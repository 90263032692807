/* eslint-disable react/no-array-index-key */
import React from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
// import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useTheme } from '@mui/system';
import trackUse from '@utils/trackUse';
import { DealPostType } from '@types';
import { logPostHogEvent } from '@utils/index';
import { SubscribeSaveExplanation } from '..';

const SubscribeSaveInfo = ({ maxSs }: { maxSs?: number }) => {
  const theme = useTheme();

  return (
    <Box
      maxWidth="600px"
      sx={{
        backgroundColor: theme.palette.background.default,
        p: 3,
        mb: 3
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '1.5rem',
          mb: 2,
          textAlign: 'center',
          color: theme.palette.primary.main
        }}
        component="h3"
      >
        Subscribe & Save - Up to {maxSs || 5}% off discount!
      </Typography>
      <Typography
        sx={{
          fontSize: '1rem',
          lineHeight: 1.7,
          color: theme.palette.text.primary,
          mb: 2
        }}
      >
        Unlock up to 15% savings with Amazon Subscribe & Save! This feature lets
        you set up regular deliveries of your favorite products, saving you time
        and money. The more you subscribe, the more you save – enjoy up to 15%
        off on orders with at least 5 products.
      </Typography>
      <Typography
        sx={{
          fontSize: '1rem',
          lineHeight: 1.7,
          color: theme.palette.text.primary,
          mb: 3
        }}
      >
        You&apos;re in control with easy cancellation anytime. For peace of
        mind, we suggest setting up deliveries every 6 months, giving you ample
        time to cancel before any potential price adjustments.
      </Typography>
      <Box maxWidth="600px">
        <SubscribeSaveExplanation />
      </Box>
    </Box>
  );
};

const SubscribeSaveChip = (props: DealPostType) => {
  const { ASIN, maxSs } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [ssChipAnchorEl, setSSChipAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const ssChipOpen = Boolean(ssChipAnchorEl);

  const handleSSChipClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setSSChipAnchorEl(event.currentTarget);
    trackUse({
      item: 'open-subscribe-save-chip',
      value: ASIN,
      type: 'CLICK'
    });

    logPostHogEvent('open-subscribe-save-chip', {
      ASIN,
      type: 'CLICK'
    });
  };

  const handleSSChipClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSSChipAnchorEl(null);
  };

  const styles = {
    marginRight: '4px !important',
    backgroundColor: isDarkMode
      ? 'transparent !important'
      : '#00796b !important',
    borderColor: isDarkMode ? '#00796b !important' : 'transparent !important',
    color: 'white !important',
    marginTop: '0.35em !important',
    fontSize: '11px'
  };
  return (
    <span key="ss-chip">
      <Tooltip
        enterDelay={1000}
        placement="top"
        title={`Subscribe & Save for up to an extra ${
          maxSs || '5'
        }% discount. Click for details.`}
      >
        <Chip
          sx={styles}
          aria-describedby={`ss_chip_${ASIN}`}
          size="small"
          onClick={handleSSChipClick}
          label={`S&S (${maxSs || 5}%)`}
          variant={isDarkMode ? 'outlined' : 'filled'}
        />
      </Tooltip>
      <Popover
        id={`ss_chip_${ASIN}`}
        open={ssChipOpen}
        anchorEl={ssChipAnchorEl}
        onClose={handleSSChipClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SubscribeSaveInfo maxSs={maxSs} />
      </Popover>
    </span>
  );
};

export default SubscribeSaveChip;
