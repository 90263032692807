import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { GoogleLogin } from '@react-oauth/google';

import { Alert } from '@mui/material';
import { useTheme } from '@mui/system';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSignin } from '@hooks/useSignin';
import useLocalStorage from '@hooks/useLocalStorage';
import { useSignUpModal } from '@hooks/useLoginModal';
import trackUse from '@utils/trackUse';
import { isValidEmail, logPostHogEvent } from '@utils/index';
import { LoadingSmall } from '../Loading';

const EmailTextField = ({
  email,
  setEmail,
  disabled
}: {
  email: string;
  setEmail: (password: string) => void;
  disabled: boolean;
}) => (
  <TextField
    data-testid="email-input"
    label="Email"
    variant="outlined"
    size="small"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    autoComplete="email"
    disabled={disabled}
  />
);

const PasswordTextField = ({
  password,
  setPassword,
  disabled
}: {
  password: string;
  setPassword: (password: string) => void;
  disabled: boolean;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <TextField
      data-testid="password-input"
      label="Password"
      variant="outlined"
      size="small"
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      autoComplete="current-password"
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={togglePasswordVisibility}
              onMouseDown={(e) => e.preventDefault()} // Prevent focus on button click
              edge="end"
              aria-label="toggle password visibility"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

const Signin = ({
  showInCard = false,
  showDontHaveAccount = true,
  redirectTo = null
}: {
  showInCard?: boolean;
  showDontHaveAccount?: boolean;
  redirectTo?: string | null;
}) => {
  const theme = useTheme();
  const history = useHistory();
  const [email, setEmail] = useLocalStorage('form_email', '');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const { mutate: signin, isLoading: isLoggingIn } = useSignin();
  const { data: user, isLoading: isFetchingUser } = useGetUserData();
  const { handleClose } = useSignUpModal();

  useEffect(() => {
    if (user?.firstName && !user?.isSuspended && redirectTo) {
      setTimeout(() => {
        history.push(redirectTo);
      }, 1000);
    }
  }, [user, redirectTo]);

  const isSubmitButtonEnabled = () => {
    if (!isValidEmail(email) || isLoggingIn) return false;
    // TODO: Better password requirements
    if (password.trim().length < 4) return false;
    return true;
  };

  const successFunction = (data: {
    isSuspended: boolean;
    hasAccount: boolean;
  }) => {
    const { isSuspended } = data;
    if (isSuspended) {
      setHasError(true);
      setHasLoggedIn(true);
    } else {
      setHasLoggedIn(true);
      setHasError(false);
    }
  };

  const signinResponse = {
    onSuccess: successFunction,
    onError: () => {
      setHasError(true);
    }
  };

  const submitSignin = () => {
    if (!isSubmitButtonEnabled()) {
      return;
    }
    signin({ email, password }, signinResponse);
  };

  if (hasLoggedIn) {
    if (hasError) {
      return (
        <Alert
          severity="error"
          sx={{
            margin: '1rem'
          }}
        >
          Your account has been suspended for using a disposable email address.
          Please contact us at suspensions@jungle.deals if you believe this is
          an error or sign up with a personal gmail account to reverse the
          suspension.
        </Alert>
      );
    }

    return (
      <Alert
        severity="success"
        sx={{
          margin: '1rem'
        }}
      >
        {`You have successfully logged in! ${
          redirectTo ? `Redirecting...` : ''
        }`}
      </Alert>
    );
  }

  if (isFetchingUser || user) {
    return null;
  }

  const mainLogic = () => {
    return (
      <>
        {isLoggingIn ? <LoadingSmall text="Logging you in..." /> : null}
        {hasError ? (
          <Box
            sx={{
              margin: '0 auto 1rem auto',
              maxWidth: '400px'
            }}
          >
            <Alert severity="error">
              Invalid email or password. Either{' '}
              <NavLink
                to="/password-reset"
                onClick={() => {
                  handleClose();
                  trackUse({
                    item: 'password-reset-click',
                    value: `password-reset-click-signin`,
                    type: 'CLICK'
                  });

                  logPostHogEvent('reset-password-click', {
                    type: 'CLICK'
                  });
                }}
              >
                reset your password
              </NavLink>{' '}
              or{' '}
              <NavLink
                to="/signup"
                onClick={() => {
                  handleClose();
                  trackUse({
                    item: 'signup-click',
                    value: `signup-click`,
                    type: 'CLICK'
                  });

                  logPostHogEvent('signup-click', {
                    type: 'CLICK'
                  });
                }}
              >
                register for an account
              </NavLink>
              .
            </Alert>
          </Box>
        ) : null}
        {!isFetchingUser && (
          <>
            {showDontHaveAccount && !hasError ? (
              <>
                <Typography
                  variant="body1"
                  style={{
                    textAlign: 'center',
                    paddingBottom: 16
                  }}
                  onClick={() => {
                    handleClose();
                    trackUse({
                      item: 'register-signup-click',
                      value: `register-signup-click`,
                      type: 'CLICK'
                    });

                    logPostHogEvent('register-signup-click', {
                      type: 'CLICK'
                    });
                  }}
                >
                  Don&apos;t have an account?{' '}
                  <NavLink
                    to="/signup"
                    style={{
                      color: theme.palette.linkColor,
                      marginRight: '4px'
                    }}
                  >
                    Register here
                  </NavLink>
                  or below with Google.
                </Typography>
                <Box
                  sx={{
                    margin: '0 auto 16px auto',
                    textAlign: 'center',
                    maxWidth: '400px'
                  }}
                >
                  <GoogleLogin
                    text="signup_with"
                    onSuccess={(credentialResponse: { credential: string }) => {
                      signin(credentialResponse, signinResponse);
                    }}
                    onError={() => {
                      trackUse({
                        item: 'sign-up-with-fail-click',
                        value: `sign-up-with-fail-click`,
                        type: 'ERROR'
                      });
                    }}
                    useOneTap
                  />
                </Box>
              </>
            ) : null}
            <form onSubmit={submitSignin}>
              <Box
                sx={{
                  margin: '0 auto',
                  textAlign: 'center',
                  maxWidth: '400px'
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    marginBottom: '1rem',
                    textAlign: 'left'
                  }}
                >
                  Login
                </Typography>
                <Box marginBottom="16px">
                  <GoogleLogin
                    onSuccess={(credentialResponse: { credential: string }) => {
                      signin(credentialResponse, signinResponse);
                    }}
                    onError={() => {
                      trackUse({
                        item: 'login-fail-click',
                        value: `login-fail-click`,
                        type: 'ERROR'
                      });
                    }}
                    useOneTap
                  />
                </Box>
                <Stack spacing={3}>
                  <EmailTextField
                    email={email}
                    setEmail={setEmail}
                    disabled={isLoggingIn}
                  />
                  <PasswordTextField
                    password={password}
                    setPassword={setPassword}
                    disabled={isLoggingIn}
                  />
                </Stack>
                <NavLink
                  to="/password-reset"
                  style={{
                    display: 'block',
                    textAlign: 'left',
                    color: theme.palette.linkColor
                  }}
                  onClick={() => {
                    handleClose();
                    trackUse({
                      item: 'password-reset-click',
                      value: `password-reset-click-signin-2`,
                      type: 'CLICK'
                    });

                    logPostHogEvent('password-reset-click', {
                      type: 'CLICK'
                    });
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      paddingTop: '8px'
                    }}
                  >
                    Reset password
                  </Typography>
                </NavLink>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    sx={{
                      marginTop: '10px'
                    }}
                    onClick={submitSignin}
                    variant="contained"
                    disabled={!isSubmitButtonEnabled()}
                  >
                    Login
                  </Button>
                </Box>
              </Box>
            </form>
          </>
        )}
      </>
    );
  };

  if (showInCard) {
    return (
      <Card
        raised
        sx={{
          margin: '1rem 0',
          padding: '20px',
          '@media (max-width: 540px)': {
            margin: '1rem 0',
            padding: '20px'
          }
        }}
      >
        {mainLogic()}
      </Card>
    );
  }

  return <Box>{mainLogic()}</Box>;
};

export default Signin;
