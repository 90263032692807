import React from 'react';
import { CardMedia } from '@mui/material';
import config from '@configFile';
import { getProductLink, getPostPrice } from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';
import { DealPostCardProps } from '@components/DealCard/utils';

const DealCardImage = (dealProps: DealPostCardProps): JSX.Element => {
  const {
    previewMode,
    ASIN,
    image240Url,
    image240Height,
    image240Width,
    image600Url,
    image,
    shouldLazyLoad,
    tag = config.AFFILIATE_TAGS.DEFAULT
  } = dealProps;

  const link = getProductLink(ASIN, tag);

  const image240UrlWebp = image240Url?.replace('.jpg', '.webp');
  const image600UrlWebp = image600Url?.replace('.jpg', '.webp');

  const imageUrl = image240UrlWebp || image600UrlWebp || image;
  const imageHeight = (image240Height || 240) * 0.8;
  const imageWidth = (image240Width || 240) * 0.8;

  return (
    <MonetizingLink
      href={link}
      sx={{
        display: 'block'
      }}
      tag={tag}
      clickType="deal-post-card-image"
      aria-label={`View deal details${getPostPrice(dealProps)} at Amazon.com`}
    >
      <CardMedia
        component="img"
        alt=""
        data-testid="deal-card-media-image"
        className="deal-card-media-image"
        image={imageUrl}
        loading={shouldLazyLoad ? 'lazy' : 'eager'}
        sx={{
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
          maxWidth: `${imageWidth}px!important`,
          maxHeight: `${imageHeight}px!important`,
          display: 'block',
          margin: '0px auto 16px auto',
          textAlign: 'center',
          filter: previewMode ? 'blur(8px)' : 'none',
          // more than 600px width
          '@container (min-width: 700px)': {
            maxWidth: `${imageWidth}px!important`,
            maxHeight: `${imageHeight}px!important`,
            width: `${imageWidth}px`,
            height: `${imageHeight}px`
          }
        }}
      />
    </MonetizingLink>
  );
};

export default DealCardImage;
