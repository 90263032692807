/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import axios from '@utils/axios';
import queryClient from '../queryClient';
import { DealFeatureType } from '../types';

const queryFeatures = async (ASIN: string): Promise<DealFeatureType[]> => {
  const result = await axios.get<{
    data: DealFeatureType[];
  }>(`/api/dashboard/get-features?ASIN=${ASIN}`);
  return result.data;
};

export function useGetFeatures(
  ASIN: string
): UseQueryResult<DealFeatureType[]> {
  return useQuery<DealFeatureType[], Error>(
    `product-features-${ASIN}`,
    () => queryFeatures(ASIN),
    {
      enabled: false,
      onError: () => {
        console.error(
          'Something went wrong while fetching the product features'
        );
      }
    }
  );
}

export function useEditFeature() {
  interface RequestData {
    feature: string;
    id: number;
  }

  const mutationFn = async ({ feature, id }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/edit-feature`, {
      feature,
      id
    });
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error editing feature');
    }
  });
}

export function useDeleteFeature() {
  interface RequestDeleteData {
    id: number;
  }

  const mutationFn = async ({ id }: RequestDeleteData): Promise<null> => {
    return axios.post(`/api/dashboard/delete-feature`, {
      id
    });
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error deleting feature');
    }
  });
}

export function useAddFeature() {
  interface RequestAddData {
    ASIN: string;
    feature: string;
  }

  const mutationFn = async ({
    ASIN,
    feature
  }: RequestAddData): Promise<null> => {
    return axios.post(`/api/dashboard/add-feature`, {
      ASIN,
      feature
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (_data, req) => {
      queryClient.refetchQueries(`product-features-${req.ASIN}`);
    },
    onError: () => {
      console.error('error adding feature');
    }
  });
}
