import { useQuery, UseQueryResult } from 'react-query';
import axios from '@utils/axios';
import { DealPostType } from '../types';
import queryClient from '../queryClient';

const queryDealSummary = async (
  ASIN: string,
  refreshCache = false
): Promise<DealPostType> => {
  let requestStr = `/api/get-deal-summary?ASIN=${ASIN}`;
  if (refreshCache) {
    requestStr += '&refreshCache=true';
  }

  const result = await axios.get<{
    data: DealPostType;
  }>(requestStr);
  return result.data;
};

export function useGetDealSummary(ASIN: string): UseQueryResult<DealPostType> {
  return useQuery<DealPostType, Error>(
    `deal-summary-${ASIN}`,
    () => queryDealSummary(ASIN),
    {
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}

const queryDealSummaryAdminMain = async (
  ASIN: string
): Promise<DealPostType> => {
  const result = await axios.get<{
    data: DealPostType;
  }>(`/api/dashboard/get-deal-summary-admin?ASIN=${ASIN}`);
  return result.data;
};

export function useGetDealSummaryAdminEnabled(
  ASIN: string
): UseQueryResult<DealPostType> {
  return useQuery<DealPostType, Error>(
    `deal-summary-admin-${ASIN}`,
    () => queryDealSummaryAdminMain(ASIN),
    {
      enabled: true,
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}

export function useGetDealSummaryNonEnabled(
  ASIN: string,
  refreshCache = false
): UseQueryResult<DealPostType> {
  return useQuery<DealPostType, Error>(
    `deal-summary-${ASIN}`,
    () => queryDealSummary(ASIN, refreshCache),
    {
      onSuccess: () => {
        if (refreshCache) {
          queryClient.refetchQueries('latest-deals-db');
          queryClient.refetchQueries(`deal-summary-admin-${ASIN}`);
        }
      },
      enabled: false,
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}

export function useGetDealSummaryAdmin(
  ASIN: string
): UseQueryResult<DealPostType> {
  return useQuery<DealPostType, Error>(
    `deal-summary-admin-${ASIN}`,
    () => queryDealSummaryAdminMain(ASIN),
    {
      enabled: false,
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}
