import React from 'react';
import { Grid } from '@mui/material';
import config from '@configFile';

import UpcomingEvents from '@components/UpcomingEvents';
import FeedSidebar from '@pages/Blog/components/FeedSidebar';
import TopDealsCard from '@components/TopDealsCard';
import SubscribeSaveExplanation from '@components/SubscribeSaveExplanation';

interface PageGridProps {
  children?: React.ReactNode;
}

const PageGrid = ({ children = null }: PageGridProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        {children}
      </Grid>
      <Grid
        item
        sx={{
          display: { xs: 'none', md: 'block' },
          paddingBottom: `1rem`
        }}
        xs={12}
        md={4}
      >
        <TopDealsCard tag={config.AFFILIATE_TAGS.FEED} />
        <SubscribeSaveExplanation />
        <UpcomingEvents />
        <FeedSidebar />
      </Grid>
    </Grid>
  );
};

export default PageGrid;
