import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import TocIcon from '@mui/icons-material/Toc';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useGetUserData } from '@hooks/useGetUserData';
import { getPostPrice, parseTitle } from '@utils/index';
import CopyBlogLink from '@components/AdminSlugActions/components/CopyBlogLink';
import { DealPostType } from '@types';
import {
  useGetDealSummary,
  useGetDealSummaryNonEnabled
} from '@hooks/useGetDealSummary';
import { useGetProductImagesNonEnabled } from '@hooks/useProductImages';
import { dealsAction } from '../../actions';

import SendMailing from '../SendMailing';
import { iconButtonHoverRaiseStyle } from '../../utils/styles';
import ReportInaccurate from '../ReportInaccurate';
import CompareDetails from './CompareDetails';
import DeleteDeal from './DeleteDeal';
import SeeStats from './SeeStats';
import ChangeInfo from './ChangeInfo';
import { ImageSwitchingModalButton } from './ImageSwitchingModal';
import ProductImages from './components/ProductImages';

const AdminActions = ({ ASIN, deal }: { ASIN: string; deal: DealPostType }) => {
  const history = useHistory();
  const location = useLocation();
  const currentSlug = location.pathname?.split('/')?.pop();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: user } = useGetUserData();
  const subject = `${parseTitle(deal.title)}${getPostPrice(deal)}`;
  const dispatch = useDispatch();
  const { refetch } = useGetDealSummaryNonEnabled(ASIN, true);
  const { refetch: refetchProductImages } = useGetProductImagesNonEnabled(ASIN);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!user?.isAdmin) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        id="admin-action-button"
        aria-label="Admin actions"
        aria-haspopup="true"
        onClick={(e) => {
          e.preventDefault();
          handleClick(e);
        }}
        size="small"
        aria-expanded={open ? 'true' : undefined}
        sx={iconButtonHoverRaiseStyle}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <ChangeInfo ASIN={ASIN} onCloseCallback={() => handleClose()} />
        <ProductImages ASIN={ASIN} onCloseCallback={() => handleClose()} />
        <CopyBlogLink
          {...deal}
          key="copy-blog-link"
          handleClose={handleClose}
        />
        <ImageSwitchingModalButton
          key="admin-switch-image"
          ASIN={ASIN}
          closeCallback={() => {
            handleClose();
          }}
        />
        <SeeStats ASIN={ASIN} onCloseCallback={() => handleClose()} />
        <SendMailing
          appearance="menuItem"
          onCloseCallback={() => {
            handleClose();
          }}
          ASIN={ASIN}
          subject={subject}
        />
        <CompareDetails
          ASIN={ASIN}
          onCloseCallback={() => {
            handleClose();
          }}
        />
        <ReportInaccurate
          ASIN={ASIN}
          closeDialog={() => {
            handleClose();
          }}
          variant="listItem"
        />
        {deal.slug ? (
          <MenuItem
            key="go-to-blog"
            onClick={() => {
              history.push(`/blog/${deal.slug}`);
              dispatch(dealsAction.getBlogSEO(deal.slug));
              handleClose();
            }}
          >
            <ListItemIcon>
              <LaunchIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Go To Blog Post</ListItemText>
          </MenuItem>
        ) : null}
        {deal.slug ? (
          <MenuItem
            key="refresh-deal"
            onClick={() => {
              refetch();
              refetchProductImages();
              if (currentSlug === deal?.slug) {
                dispatch(dealsAction.getBlogSEO(deal?.slug));
              }
              handleClose();
            }}
          >
            <ListItemIcon>
              <AutorenewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Refresh Deal</ListItemText>
          </MenuItem>
        ) : null}
        <MenuItem
          key="see-variations"
          onClick={() => {
            history.push(`/variations?ASIN=${ASIN}`);
            setTimeout(() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            }, 250);
          }}
        >
          <ListItemIcon>
            <TocIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Search Variations</ListItemText>
        </MenuItem>
        <DeleteDeal ASIN={ASIN} onCloseCallback={() => handleClose()} />
      </Menu>
    </>
  );
};

const AdminASINActionsByASINChild = ({ ASIN }: { ASIN: string }) => {
  const { data: dealSummary } = useGetDealSummary(ASIN);

  if (!dealSummary) {
    return null;
  }

  return <AdminActions deal={dealSummary} ASIN={ASIN} />;
};

export const AdminActionsByASIN = ({ ASIN }: { ASIN: string }) => {
  const { data: user } = useGetUserData();

  if (!user?.isAdmin) {
    return null;
  }

  return <AdminASINActionsByASINChild ASIN={ASIN} />;
};

export default AdminActions;
