import React from 'react';
import { Alert, AlertTitle, Typography, Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CloseIcon from '@mui/icons-material/Close';
import trackUse from '@utils/trackUse';
import MonetizingLink from '@components/MonetizingLink';
import { addAffiliateTagToLink } from '@utils/index';
import useLocalStorage from '@hooks/useLocalStorage';
import config from '@configFile';

const SubscribeSaveTip: React.FC = () => {
  const [visible, setVisible] = useLocalStorage(
    'subscribeAndSaveTipVisible',
    true
  ); // State to control visibility
  const theme = useTheme();
  const tag = config.AFFILIATE_TAGS.JD_SUBSCRIBE_SAVE_CANCEL;

  if (!visible) return null; // Don't render if dismissed

  return (
    <Box sx={{ maxWidth: 600, margin: '20px auto', padding: '10px' }}>
      <Alert
        icon={<LightbulbIcon fontSize="large" />}
        severity="info"
        sx={{
          backgroundColor:
            theme.palette.mode === 'dark' ? '#333333' : 'lightyellow',
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
          borderRadius: 2,
          boxShadow: 2,
          border:
            theme.palette.mode === 'dark' ? '1px solid #444' : '1px solid #ddd',
          position: 'relative'
        }}
        action={
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => {
              trackUse({
                item: 'subscribe-save-tip-dismiss',
                value: `tip-dismissed`,
                type: 'ACTION'
              });

              setVisible(false);
            }} // Hide the tip on click
            sx={{
              color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000'
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <AlertTitle
          sx={{ color: theme.palette.mode === 'dark' ? '#f9f871' : '#000' }}
        >
          Amazon Subscribe & Save Tip
        </AlertTitle>
        <Typography>
          A useful tip when ordering with Amazon Subscribe & Save is to set all
          subscriptions to <strong>6 months</strong> and then cancel before the
          item ships again. This ensures you give yourself the maximum amount of
          time to cancel without incurring price increases on recurring orders.{' '}
          <MonetizingLink
            href={addAffiliateTagToLink(
              'https://www.amazon.com/gp/subscribe-and-save/manager/viewsubscriptions?ref_=ya_d_l_subscribe_save',
              tag
            )}
            tag={tag}
            clickType="subscribe-save-tip-cancel-link"
            target="_blank"
            sx={{
              fontWeight: 700
            }}
            underline="hover"
          >
            Cancel at this page any time.
          </MonetizingLink>{' '}
        </Typography>
      </Alert>
    </Box>
  );
};

export default SubscribeSaveTip;
